import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";

function AuthPage(props) {
  const router = useRouter();
  const authType = router.query.type;
  
  // Determine afterAuthPath based on authType
  const afterAuthPath = authType === "signin" ? "/dashboard" : "/onboarding";

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        type={authType}
        //providers={["google", "facebook", "twitter"]}
       // providers={[]}

        afterAuthPath={afterAuthPath}
      />
    </>
  );
}

export default AuthPage;
