import React from "react";

function LegalTerms(props) {
  return (
    //<div className="prose prose-a:text-blue-600 max-w-none">
<> 
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >

<p style={{ textAlign: "center" }}>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "12pt"
        }}
      >
        Terms of Service
      </span>
    </strong>
  </p>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      <br className="Apple-interchange-newline" />
      Effective Date:
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;Jul 25, 2024
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Welcome to The Bookroad! We are so grateful you have joined our community.
      For everyone to enjoy the community, we require all of our users to comply
      with our terms and conditions.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      These Terms of Service (the “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Terms
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) apply to your access to and use of The Roadbook’s Service (as defined
      below). Please read them carefully.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      1. Your Acceptance of These Terms
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This section describes how these Terms form a binding contract between you
      and us. If you use the service on behalf of a legal entity, the legal
      entity also agrees to these Terms.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      These Terms are a legally binding contract (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Agreement
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) between you and The Bookroad. When we say “you” or “your,” we mean you
      individually and any legal entity exercising rights under this Agreement
      through you. When we say “The Bookroad,” “we,” “our,” or “us,” we mean The
      Bookroad.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      By accessing and/or using the Service, you agree to be bound by this
      Agreement and any policies or guidelines incorporated by reference into
      this Agreement (which will become part of the Agreement). If you do not
      agree to abide by all of the terms and conditions in this Agreement, you
      must not access and/or use the Service. You represent that you have the
      authority to bind yourself to this Agreement. If you access and/or use the
      Service on behalf of a legal entity, then you represent that you have
      authority to bind that legal entity to this Agreement and that the legal
      entity accepts this Agreement.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This Agreement applies to the The Bookroad platform and all related
      content, tools, features, and services, including the The Bookroad mobile
      app (on any and all formats or devices), and the website located at
      https://www.thebookroad.com (collectively, the “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Service
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). The Service provides an online social marketplace where users of the
      Service (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Users
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) can list and exchange items (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Items
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). When Users receive Items, they are “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receivers
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ,” and when Users send out items, they are “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Senders
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      .” Users may, at different times, be both Receivers and Senders on the
      Service. By using the Service, Receivers and Senders enter into a contract
      for the exchange of items directly between themselves, and The Bookroad is
      not a party to such exchanges.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Please read this Agreement carefully as it, to the extent legally
      allowable in your jurisdiction, requires you and The Bookroad to resolve
      most disputes solely through arbitration on an individual basis and not as
      a class arbitration, class action, any other kind of representative
      proceeding, or by jury trial. The Bookroad will not be liable for any loss
      or damage arising from your failure to comply with the Agreement.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      2. Eligibility and Your The Bookroad Account&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This section describes who is eligible to use the Service and your
      responsibilities to protect your Account password and provide us with
      accurate information.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Minors Must Have Permission to Use the Service
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You may not use the Service if you are under the age of 13. If you are at
      least 13 but under the age of 18, you may only use the Service with
      permission of your parent or guardian.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Provide Accurate Information
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You need to create an account with The Bookroad to access and/or use
      certain features of the Service (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Account
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). If you are creating an Account for a legal entity, then you represent
      and warrant that you have the authority to create such Account on behalf
      of the business entity. You must provide accurate information about your
      identity and must not provide any false information or impersonate another
      person or company through your Account. The Bookroad reserves the right to
      verify the account information you provide (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Account Information
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). Upon The Bookroad’s request, you will promptly provide requested
      verification documentation, including identification documents, proof of
      address, or business information. The name on your Account will be
      considered the Account owner. If you do not provide the information
      requested or if you provide inaccurate information, The Bookroad may, in
      its sole discretion, terminate your Account.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      c. Protect Your Password
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You are responsible for maintaining the confidentiality of your password
      and Account. You will immediately notify us of any suspected unauthorized
      use of your Account or any other suspected breach of Account
      security.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      d. Your Responsibility for Your Account
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You are solely responsible for any activity on your Account. If you are a
      Minor and sharing the Account with a parent or guardian, then both you and
      the parent or guardian granting permission on the Account will be
      responsible for any activity on the Account.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      e. Account Transfers
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You cannot transfer or assign your Account to a third party without prior
      written consent from The Bookroad. The Bookroad may require additional
      information from you regarding the requested transfer or assignment. You
      cannot otherwise transfer, sell, or dispose of your Account under any
      circumstances.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      3. Your Privacy
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      For information about how we collect, use, share, and otherwise process
      information about you, see our Privacy Policy.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      4. Your Use of the Service
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You may only use the Service if you comply with the law and this
      Agreement. If you do not comply, then we can take certain actions,
      including termination of your Account or other legal or investigatory
      action.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Your Use of the Service and Interactions with Other Users
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      By using the Service, you may interact with other Users. You release us
      from all liability relating to your interactions with other Users. When
      interacting with other Users, you assume all the risks of your
      interactions. You will be solely responsible and liable for your use of
      the Service, including sending, receiving, transacting, exchanging, and
      communicating on the Service. All forms of interaction must be respectful,
      safe, and real.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Follow the Agreement and the Law
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      In connection with using and/or accessing the Service, you will comply
      with this Agreement and all applicable laws, rules, and regulations.
      Without limiting the forgoing, you will not do any of the following in
      connection with your use of the Service:
    </span>
  </p>
  <ul
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      marginTop: 0,
      marginBottom: 0,
      paddingInlineStart: 48
    }}
  >
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          Post any User Content (each as defined below in Section 6) that is
          unlawful, harmful, violent, illegal, infringing on third party rights,
          false, misleading, fraudulent, pornographic, libelous, invasive,
          encouraging of money laundering, gambling, or any other unlawful or
          unwarranted behavior, or otherwise fail to comply with being
          respectful, safe, and real.&nbsp;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          violate any law, rule, or regulation, including any anti-spam, data
          privacy, or other restriction, as determined by The Bookroad in its
          sole discretion, that may be applicable to your use of the Service;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          create any derivative works of, modify, or reverse engineer any part
          of the Service;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          interfere with, disrupt, negatively affect, or inhibit other Users
          from fully enjoying our Service or damage, disable, overburden, put
          unreasonable load on, or otherwise impair the functioning of the
          Service infrastructure or the networks connected to the Service;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          promote any illegal activity or enterprise;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          stalk, harass, bully, impersonate or solicit information unrelated to
          a listed Book from anyone;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          copy, scrape, harvest, crawl or use any technology, software or
          automated systems to collect any information or data for the Service;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          sell or transmit anything you do not have a right to sell or transmit
          under law or existing relationship;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          pose a privacy or security risk to the Service or any User;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          infringe anyone’s intellectual property, such as by posting
          counterfeit products for sale through the Service;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          spam any Users with email, junk mail, fraud, schemes, or the like;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          collect or harvest any information or data from The Bookroad’s systems
          or attempt to decipher any transmission to or from the servers running
          the Service;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          use the contact information of any User of the Service (including
          Receivers) for any purposes other than in relation to: (a) receiving
          the Service and (b) any transactions between Users;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          use the Service in any way that is contrary to The Bookroad’s public
          image, goodwill, or reputation;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          express or imply that any of your statements, activities, or causes
          are endorsed by The Bookroad, without The Bookroad’s prior written
          consent in each instance. Notwithstanding the foregoing, a Sender will
          be entitled to make a factual reference which states that the Sender
          sells its Items on the Service. All other references (including any
          commentary on the factual reference mentioned above) will be subject
          to The Bookroad’s prior written consent in each instance;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          create multiple account to evade punishment or avoid restrictions;
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          transmit or upload viruses, worms, or otherwise interfere with the
          Service; and/or
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "disc",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          take any action or inaction which The Bookroad, in its sole judgment,
          believes is questionable or could cause harm or liability.
        </span>
      </p>
    </li>
  </ul>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad may investigate, take legal action, or perform any other
      action it deems necessary or warranted in managing the Service, Content,
      or Users, including termination of your Account and/or preservation of
      such information for investigative purposes. Enforcement of this Section 4
      is solely at The Bookroad’s discretion, and failure to enforce this
      section in some instances does not constitute a waiver of our right to
      enforce it in other instances.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      c. Share Your Thoughts and Ideas
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We appreciate your thoughts and ideas. You acknowledge and agree that any
      comments, suggestions, ideas, feedback, or other information about the
      Service (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Feedback
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”), provided by you to The Bookroad will not be confidential or
      proprietary, and The Bookroad shall be entitled to the unrestricted use
      and dissemination of the Feedback for any purpose, commercial or
      otherwise, without acknowledgment or compensation to you.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      5. Your Content
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You are responsible for the Content (as defined below) you post through
      the Service and represent that you own the Content or have the right to
      use the Content. While you continue to own all your Content, you license
      the Content to us for our use, including to operate the Service and for
      our marketing purposes.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Your Content is Your Responsibility
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You are solely responsible for all information, descriptions, pictures,
      listings, text, music, videos, streaming video, media, comments, messages,
      sales, purchases, and any other materials (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Content
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) that you upload, post, publish, transmit, display, and/or submit (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Post
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) to or through the Service (such Content Posted by Users, excluding
      Account Information, is referred to as “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      User Content
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). You are solely responsible for all of your User Content, and you
      understand and agree that all User Content can be shared with other Users
      and third parties in The Bookroad’s sole discretion. The Bookroad has no
      control over or liability for how other Users may use your User Content.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. You License Your Content to Us
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      By Posting any User Content, you hereby represent and warrant that you
      have sufficient rights in the User Content to grant us the below license.
      By Posting any User Content, you hereby grant to The Bookroad and its
      affiliated companies a nonexclusive, worldwide, royalty free, fully paid
      up, transferable, sublicensable, perpetual, irrevocable license to copy,
      display, transmit, perform, distribute, store, modify, make derivative
      works of, and otherwise use in any manner, as determined by The Bookroad,
      your User Content in connection with (i) the operation of the Service or
      any other products or services of The Bookroad or (ii) the promotion,
      advertising or marketing thereof, in any form, medium or technology now
      known or later developed, including publishing your User Content on the
      internet or Third Party Services (e.g. Facebook, Twitter, Instagram,
      blogs, etc.) and allowing other Users to share listings that include your
      User Content.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      c. Your Content Must Not Infringe Intellectual Property Rights
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You further represent and warrant that your User Content does not infringe
      the intellectual property rights, privacy rights, publicity rights, or
      other legal rights of any third party. While we are not obligated to
      review any User Content posted by our Users on our Service, we reserve the
      right to review any User Content, with or without notice, to prevent or
      rectify any alleged violations of this Agreement or any applicable law. We
      may refuse to accept or display the User Content, and we may remove or
      delete all or any portion of the User Content at any time in our sole
      discretion, with or without cause. The Bookroad will not be responsible
      for any User Content, including the accuracy, usefulness, safety, or
      intellectual property rights of or relating to any User Content.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You may be exposed to User Content that you find inaccurate, offensive,
      indecent, or otherwise objectionable, and you waive any legal or equitable
      rights or remedies you have or may have against the The Bookroad Parties
      (as defined in Section 11) with respect to such User Content.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      d. Your Content Is Public
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You understand and agree that (i) the User Content you Post is not
      confidential and will be treated as non-confidential; (ii) your User
      Content may be publicly viewable and will be accessible and viewed by
      third parties; and (iii) The Bookroad will not be liable for any treatment
      of your User Content as public and non-confidential or for the use of your
      User Content by third parties. You waive all claims with respect to
      confidentiality of any User Content. If you do not want your User Content
      to become public and nonconfidential, you must not use the Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      6. Intellectual Property Complaints
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You may notify us if you believe your work has been infringed on the
      Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      In accordance with the Digital Millennium Copyright Act (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      DMCA
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) and other applicable law, we have adopted a policy of terminating, in
      appropriate circumstances, the accounts of users who repeatedly infringe
      the intellectual property rights of others.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If you believe that anything on our Service infringes any of your
      intellectual property rights, including copyright that you own or control,
      please email us at bookroadcontact@gmail.com.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Please note that if you knowingly misrepresent that any activity or
      material on our services is infringing, you may be liable to us for
      certain costs and damages.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      7. Third-Party Services and Content
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You may access or use services or content provided by third parties when
      using our Service. The Bookroad is not responsible for such third party
      services or content, and third-party services will be governed by such
      third parties’ terms and conditions and policies.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Your Access to Services of Third Parties
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Service may enable you to access certain services that are offered by
      third-party service providers, including through links to third-party
      sites, video, email, telephone communication, and/or other offers outside
      of the The Bookroad platform (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Third Party Services
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). For example, social networks you connect your Account to under the
      Sharing Settings page are Third Party Services. Your dealings or
      correspondence with third parties and your use of or interaction with any
      Third Party Services are solely between you and the third party. The
      Bookroad does not control or endorse, and makes no representations or
      warranties regarding, any Third Party Services, and your access to and use
      of such Third Party Services is at your own risk. The terms of this
      Agreement and the The Bookroad&nbsp;
    </span>
    <a
      href="https://poshmark.com/privacy#privacy"
      style={{ textDecoration: "none" }}
    >
      <span
        style={{
          fontSize: "10.5pt",
          fontFamily: "Roboto, sans-serif",
          color: "rgb(127, 3, 83)",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantAlternates: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontVariantPosition: "normal",
          textDecoration: "underline",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Privacy Policy
      </span>
    </a>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;do not apply to your use of any such Third Party Services. You are
      solely responsible for your access or use of such Third Party Services.
      You should review any applicable terms and/or privacy policies of any
      Third Party Services before using or sharing information with any Third
      Party Services. The Bookroad may suspend or terminate your ability to
      access Third Party Services through the Services at any time with or
      without notice.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Third Party and User Content
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Service contains images, descriptions and other Content posted by
      third parties including Users (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Third Party Content
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). Content you access through Third Party Services is also Third Party
      Content. You acknowledge that Third Party Content may be protected by
      copyright, patent, trademark, trade secret, and/or other proprietary
      rights and laws. You agree not to copy, modify, scrape, distribute, create
      derivative works, or otherwise use the Third Party Content for any purpose
      other than as explicitly authorized in this Agreement.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad will not be liable for the Third Party Content or any errors,
      omissions, inaccuracies, infringement, loss, or damage of any kind related
      to the Third Party Content. You acknowledge that The Bookroad has no
      obligation to review, screen, or inspect any Third Party Content on the
      Service. However, The Bookroad reserves the right to review, screen,
      inspect, and/or remove any Third Party Content at our sole discretion. You
      agree that you assume all the risk associated with the use of or reliance
      upon Third Party Content, including risks related to accuracy,
      completeness, or usefulness.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      8. Your Purchases and Sales&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Sending, receiving, and exchanging Items through the Service include risk,
      fees, and taxes.&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;prohibits certain items from being sent or exchanged, and only
      allows returns in limited circumstances.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Your Risk&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You assume certain risks in using a marketplace service such as The
      Bookroad. The Bookroad is not a participant in and provides no warranty
      for any transaction between a Sender and a Receiver, and The Bookroad does
      not take title of any Items. You acknowledge and agree that The Bookroad
      will not be considered a sender or receiver in any transaction conducted
      through the Service and that The Bookroad is not an auctioneer, consignee,
      or a shipping carrier. When acting as a sender or receiver, you assume all
      of the risks of sending, receiving, or exchanging Items through the
      Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Senders&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      As a Sender, you agree to only Post Items that you have all rights
      necessary to sell and to describe such Items truthfully, accurately, and
      completely. Our Sender’s Policy below further describes a Sender’s rights,
      responsibilities, and fees:&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "center",
      marginTop: "0pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "12pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Sender Policy
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      As a Sender, you are central to providing each of your Receiver with a
      safe and trustworthy experience on The Bookroad. This policy is designed
      to outline a Sender rights and responsibilities and is a part of The
      Bookroad’s Terms of Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Senders are responsible for reviewing and abiding by these rules as well
      as any applicable local laws. Violating this policy may result in
      restrictions to your account, up to and including termination, and may be
      made at the sole discretion of The Bookroad.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.44",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Sender Fee Policy
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      All fees are simple and straightforward.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      It’s always free to list an item to send on The Bookroad.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      What Can Be Send On The Bookroad
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad marketplace welcomes the exchange of most types of new and
      pre-owned books.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      For the safety of our Users, items outside of our supported categories and
      otherwise prohibited or unlawful items may not be offered for exchange on
      The Bookroad. The only items that may be listed or exchanged are
      books.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Listing Descriptions and Pricing
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Senders must describe items truthfully, accurately, and completely. This
      includes creating informative listing descriptions that include your
      original photos of the items. The item for exchange should be clearly
      represented in the main photo. Avoid any photo editing techniques that may
      make it difficult for Receiver to accurately assess the condition of the
      item, such as collages or heavy special effects.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Senders must have possession of, and ship, the actual item described in
      the listing. Senders may not list a promise for a future item, or ship a
      “placeholder” note or item indicating the actual listed item will arrive
      at a future date.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Senders must promptly answer questions and requests for additional
      information. Please note that images as well as descriptions provided may
      be reviewed and considered by The Bookroad staff in the event of a
      dispute.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      c. Receiver
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      As a Receiver, you are solely responsible for reading and reviewing an
      Item’s listing before making a request for an Item or exchanging an Item.
      Our Receiver Policy below further describes a Receiver’s rights and
      responsibilities. Receivers are responsible for all fees as described in
      the Receiver Policy:&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "center",
      marginTop: "0pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "12pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receiver Policy
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      As a Receiver, you will be solely responsible for reviewing the item
      description before making a request, or exchange. Receivers who request
      “anonymous” exchanges do so at their own risk. The contract to purchase
      will be between you and the Sender and not between you and The Bookroad.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad does not physically evaluate items listed for sale and is not
      responsible for any item flaws or description inaccuracies. In the event
      of an issue with a purchase, such as an item not as described, please
      inform us within 3 days of delivery of the item to qualify for a refund
      evaluation. If an item appears to violate The Bookmark’s Terms of Use, you
      may report the listing.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Exchanges
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Once a Receiver makes a request that is accepted by the Sender, it will be
      considered a exchange and a binding contract between the Receiver and
      Sender. Upon exchanging an item, a Receiver must remit payment to The
      Bookroad for the Item, including any listed shipping or additional fees.
      The Bookroad is not an auctioneer, a Sender or a shipping carrier.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad reserves the right to delay or cancel any purchase for Trust
      and Safety purposes, including but not limited to suspected fraud and to
      protect Users from illegal or otherwise prohibited activities.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      All purchases are final with no returns, refunds, cancellations, or
      retractions allowed, except where:
    </span>
  </p>
  <ol
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      marginTop: 0,
      marginBottom: 0,
      paddingInlineStart: 48
    }}
  >
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "decimal",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          The Receiver cancels an order within three (3) hours of purchase; or
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "decimal",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "0pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          The purchase has been canceled when explicitly permitted by The
          Bookroad or if applicable, a Sender; or
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "decimal",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{
          lineHeight: "1.38",
          textAlign: "justify",
          marginTop: "0pt",
          marginBottom: "8pt"
        }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          The listing expressly indicates the availability of returns.
        </span>
      </p>
    </li>
  </ol>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Upon delivery, Receivers should promptly inspect and accept the exchange.
      Exchanges will be accepted automatically after three full days have passed
      unless the Receiver requests a return by reporting the issue within the
      Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      In the event of a dispute, Receiver approved to return may only do so with
      the prepaid shipping label provided by The Bookroad. Receiver who make
      unauthorized returns are not eligible for a refund through The Bookroad.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Payments
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receivers may pay for items only by using payment methods approved by The
      Bookroad, and The Bookroad will receive such payment from the Receiver on
      behalf of the Sender. By submitting payment information to The Bookroad,
      you authorize The Bookroad to store that information and with your
      confirmation, charge you for any items purchased. A transaction that
      begins on The Bookroad may not be completed off of The Bookroad.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Shipping Costs
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receivers are responsible for shipping costs incurred with respect to
      their requested Item(s).
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      d. Fees&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You can register for the Service for free, but The Bookroad will charge
      certain fees for various transactions you may complete through the Service
      and for certain other services The Bookroad may provide as set forth
      below. All amounts are quoted in US dollars unless otherwise stated.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      All fees are simple and straightforward.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      It’s always free to list an item for exchange on The Bookroad.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      For Senders, you will be reimbursed for shipping fees incurred for sending
      your Item, based on the shipping receipt provided. Reimbursements will be
      automatically processed for fees up to $15. For fees exceeding $15,
      reimbursement is at the sole discretion of the company.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receivers are responsible for any shipping costs incurred with respect to
      their requested Item.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      e. Fee Modifications&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We may change or discontinue, temporarily or permanently, some or all fees
      for the Service, without notice and at our sole discretion, and such
      changes will be effective upon our revision to section d in the Terms of
      Service.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      f. Prohibited Items&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad prohibits the listing or sending of any item that is
      described in our Prohibited Items Policy(which is incorporated by
      reference into this Agreement), including items that are illegal to sell
      or violate the rights of third parties such as counterfeit items.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If after reviewing an Item, we have concerns about its authenticity or
      that it otherwise constitutes a prohibited Item, we may, in our sole
      discretion, cancel the order. Senders acknowledge and agree that if such
      items are in the possession of The Bookroad, The Bookroad may determine,
      in its sole discretion, whether to return, retain, destroy, or otherwise
      dispose of such canceled item. If such Items are returned to Sender,
      Sender agrees not to attempt to relist or resell the item through the
      Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      g. Purchases
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receivers may make an offer to Senders to request Items from Senders. Once
      an offer has been accepted by the Sender, it will be considered a exchange
      and a binding contract between the Receiver and Sender. The contract to
      exchange will be solely between Receiver and Sender, and The Bookroad will
      not be a party to such contract.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Upon requesting an Item, a Receiver must remit payment to The Bookroad for
      the Item, including any listed shipping or additional fees.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The bookroad may delay or cancel any purchase for purposes of fraud
      detection or to protect Users from other illegal or wrongful activities.
      All purchases are final with no returns, refunds, cancellations, or
      retractions allowed, except where (i) the Receiver cancels an order within
      three (3) hours of purchase; (ii) The Bookroad cancels the purchase or the
      purchase has otherwise been canceled when explicitly permitted by The
      Bookroad or, if applicable, a Sender; or (iii) the listing expressly
      indicated the availability of returns.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      h. Payments
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receivers may pay for Items only by using payment methods approved by The
      Bookroad, and The Bookroad will receive such payment from the Receiver on
      behalf of the Sender. By submitting payment information through The
      Bookroad, you authorize The Bookroad to securely store that information
      and charge you for any Items purchased along with all associated taxes,
      shipping, and other fees. The Bookroad reserves the right to request
      additional information from Users for the purposes of completing any
      transactions, identity verification, fraud prevention, and any other
      regulatory and compliance purposes.&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Funds received by&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;from a transaction may, subject to the Fee Policy and your ongoing
      compliance with Section 18(a), be redeemed by Sender through&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad’s
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;permitted third-party payment provider(s) or used by the Sender to
      purchase items listed by other Senders through the Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "center",
      marginTop: "0pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "12pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(67, 67, 67)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Fee Policy
    </span>
  </p>
  <ol
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      marginTop: 0,
      marginBottom: 0,
      paddingInlineStart: 48
    }}
  >
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "decimal",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          Listing Fees:
        </span>
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          &nbsp;It is always free to list an item for sale on The Bookroad.
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "decimal",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          Shipping Fees for Senders:
        </span>
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          &nbsp;After your item is ordered, we will notify you and provide
          shipping instructions. Your shipping fees will be reimbursed based on
          the price shown on the shipping label, up to $15. For shipping fees
          exceeding $15, reimbursement is at the sole discretion of the company.
        </span>
      </p>
    </li>
    <li
      dir="ltr"
      aria-level={1}
      style={{
        listStyleType: "decimal",
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre"
      }}
    >
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          Fees for Receivers:
        </span>
        <span
          style={{
            fontSize: "10.5pt",
            fontFamily: "Roboto, sans-serif",
            color: "rgb(0, 0, 0)",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantAlternates: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fontVariantPosition: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          &nbsp;Receivers are responsible for a $10 fee to cover shipping costs
          and any additional fees. The Bookroad reserves the right to adjust
          this fee in the future. If the shipping cost exceeds $10, The Bookroad
          may request an increase in the item price to cover the additional
          cost.
        </span>
      </p>
    </li>
  </ol>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      i. Taxes&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Transactions conducted through the Service may be subject to taxes in your
      jurisdiction, which The Bookroad will collect from Receiver on behalf of
      Senders where legally obligated to do so. In the event The Bookroad
      collects taxes as legally obligated, The Bookroad will remit such taxes to
      applicable taxing authorities on behalf of Senders. The listed prices for
      Items do not include taxes, but the taxes will be displayed before a
      Receiver confirms the exchange. Taxes are based on several factors,
      including price, location, and state, local, federal, or other applicable
      rates at time of purchase. Although we may assess estimated taxes upon
      purchase or sale, you will be ultimately responsible for the verification
      and reporting of any and all applicable taxes to the appropriate tax
      authorities, including instances where The Bookroad does not collect taxes
      on your behalf.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      j. Shipping&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Receiver is responsible for paying for shipping fees as described in our
      Shipping Policy (which is incorporated by reference into this Agreement).
      The Booktoad will work with Senders to obtain a shipping label.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      k. Returns
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad may allow you to return an Item in limited
      circumstances.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      11. Limitation of Liability and Warranties&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We provide the Service on an “as-is” basis and make no other warranties or
      representations with respect to the Service or your use of the Service. We
      have no liability for certain types of damages and limit our liability for
      other types of damages.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Warranty Disclaimer
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      YOU UNDERSTAND THAT YOUR USE OF THE SERVICE, INCLUDING ANY CONTENT
      CONTAINED THEREIN, WILL BE AT YOUR SOLE RISK AND THAT THE BOOKROAD
      PROVIDES THE SERVICE, INCLUDING ANY CONTENT CONTAINED THEREIN, ON AN “AS
      IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY
      APPLICABLE LAWS, THE BOOKROAD EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
      KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED
      TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
      PURPOSE, TITLE, AND NON-INFRINGEMENT REGARDING THE SERVICE, ANY CONTENT
      CONTAINED THEREIN, OR ANY THIRD-PARTY SERVICES, MATERIALS, OR ITEMS THAT
      MAY BE ACCESSED OR PURCHASED THROUGH THE SERVICE.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE BOOKROAD MAKES NO WARRANTY
      THAT (1) YOU WILL BE ABLE TO SELL OR PURCHASE ANY ITEMS THROUGH THE
      SERVICE OR THAT THE SERVICE WILL OTHERWISE MEET YOUR EXPECTATIONS OR
      REQUIREMENTS; (2) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
      ERROR-FREE; (3) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
      SERVICE WILL BE COMPLETE, ACCURATE, OR RELIABLE; OR (4) THE QUALITY OF ANY
      GOODS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY
      YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS OR REQUIREMENTS.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      THE BOOKROAD MAKES NO WARRANTIES OR GUARANTEES OF ANY KIND REGARDING THE
      ITEMS OFFERED OR SOLD THROUGH THE SERVICE, INCLUDING THE AUTHENTICITY,
      QUALITY, SAFETY, OR LEGALITY OF SUCH ITEMS, THE TRUTH OR ACCURACY OF ANY
      LISTINGS, OR THE ABILITY OF RECEIVERS AND SENDERS TO TRANSACT ANY BUSINESS
      OR EXCHANGE THROUGH THE SERVICE. YOU ACKNOWLEDGE THAT THE BOOKROAD
      AUTHENTICATE PROGRAM CANNOT PROVIDE AN ABSOLUTE GUARANTEE OF THE
      AUTHENTICITY OF AN ITEM.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Limitation of Liability
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU UNDERSTAND AND AGREE THAT IN
      NO EVENT WILL THE BOOKROAD, OUR SUBSIDIARIES AND AFFILIATES, AND THE
      RESPECTIVE OFFICERS, DIRECTORS, AGENTS, PARTNERS AND EMPLOYEES OF EACH
      (THE “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      THE BOOKROAD PARTIES
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) BE LIABLE TO YOU UNDER ANY THEORY OF LIABILITY—WHETHER THE CLAIM IS
      BASED IN CONTRACT, TORT INCLUDING NEGLIGENCE, STRICT LIABILITY, OR
      OTHERWISE— FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL
      DAMAGES, OR LOST PROFITS ARISING OUT OF OR RELATING TO THIS AGREEMENT OR
      THE SERVICE, OR ANY ITEMS YOU PURCHASE OR SELL THROUGH THE SERVICE,
      WHETHER OR NOT A THE BOOKROAD PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR
      EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, OR
      FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE
      SERVICE, ON ANY THEORY OF LIABILITY (INCLUDING CONTRACT; TORT INCLUDING
      NEGLIGENCE OR OTHERWISE) ARISING OUT OF, IN CONNECTION WITH, OR RESULTING
      FROM (1) THE USE OR INABILITY TO USE THE SERVICE; (2) STATEMENTS OR
      CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (3) ANY OTHER MATTER RELATED
      TO THE SERVICE.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES WILL THE
      TOTAL LIABILITY OF THE THE BOOKROAD PARTIES TO YOU FOR ANY CLAIM ARISING
      OUT OF OR RELATING TO THIS AGREEMENT OR THE SERVICE, REGARDLESS OF THE
      FORM OF THE ACTION, EXCEED THE GREATER AMOUNT OF (1) COMMISSIONS THAT YOU
      HAVE PAID TO THE BOOKROAD AS A SENDER IN THE SIX (6) MONTHS PRIOR TO THE
      APPLICABLE CLAIM OR (2) ONE HUNDRED U.S. DOLLARS (USD $100).
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      THE LIMITATIONS OF THIS SECTION WILL NOT LIMIT OR EXCLUDE LIABILITY FOR
      THE GROSS NEGLIGENCE, FRAUD, OR INTENTIONAL MISCONDUCT OF THE BOOKROAD
      PARTIES OR ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY LAW.
      Our licensors and service providers will have no liability of any kind
      under this Agreement. Unless such restriction is prohibited by applicable
      law, you may not bring any claim under this Agreement more than twelve
      (12) months after the cause of action arises.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      12. Indemnification&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If we get sued because of something you did, then you agree to defend and
      indemnify us.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You agree to release, indemnify, and hold harmless and, upon The
      Bookroad’s request, defend the The Bookroad Parties from any legal claims,
      demands and damages (including reasonable attorney fees) that arise from
      or relate to (i) your access to or use of the Service, including any Items
      purchased or sold; (ii) your User Content or Feedback; (iii) your breach
      of the Agreement; (iv) your violation, misappropriation, or infringement
      of any rights of another (including someone else’s privacy or intellectual
      property rights); or (v) your violation of applicable laws. If you are a
      California resident, you waive California Civil Code Section 1542, which
      states, “A general release does not extend to claims which the creditor
      does not know or suspect to exist in his favor at the time of executing
      the release, which if known by him must have materially affected his
      settlement with the debtor.” If you are a resident of another
      jurisdiction, you waive any comparable statute or doctrine.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      13. Disputes with&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We hope to amicably resolve any disputes with you, so we strongly
      encourage you to first contact our support team at&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(127, 3, 83)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      bookroadcontact@gmail.com
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      . Any unresolved dispute will be settled by binding arbitration.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      PLEASE READ THIS SECTION CAREFULLY
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      BECAUSE IT REQUIRES YOU AND THE BOOKROAD TO ARBITRATE CERTAIN DISPUTES AND
      CLAIMS AND LIMITS THE MANNER IN WHICH WE CAN SEEK RELIEF FROM EACH OTHER.
      ARBITRATION PRECLUDES YOU AND THE BOOKROAD FROM SUING IN COURT OR HAVING A
      JURY TRIAL. YOU AND THE BOOKROAD AGREE THAT ARBITRATION WILL BE SOLELY ON
      AN INDIVIDUAL BASIS AND NOT AS A CLASS ARBITRATION, CLASS ACTION, OR ANY
      OTHER KIND OF REPRESENTATIVE PROCEEDING. THE BOOKROAD AND YOU ARE EACH
      WAIVING THE RIGHT TO TRIAL BY A JURY.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      FOLLOW THE INSTRUCTIONS BELOW IF YOU WISH TO OPT OUT OF THE REQUIREMENT OF
      ARBITRATION ON AN INDIVIDUAL BASIS.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Informal Dispute Resolution Prior to Arbitration
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      For any dispute or claim that you have against The Bookroad or that The
      Bookroad has against you arising out of or related to this Agreement or
      access to or use of the Service, including any Items purchased or sold
      (collectively, “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Claims
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”, and each a “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Claim
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”), you and The Bookroad will attempt to first resolve the Claim
      informally to resolve the Claim faster and reduce costs for both parties.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You and The Bookroad will make a good-faith effort to negotiate the
      resolution of any Claim for at least sixty (60) days (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Informal Resolution
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). This 60-day period starts on the day you or The Bookroad receive a
      written notice of a claim from the other party (a “
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Notice of Claim
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”) in accordance with this Agreement. You will send any Notice of Claim to
      The Bookroad to: Incorporating Services, Ltd., ATTN: NOTICE OF CLAIM, 7801
      Folsom Blvd #202 Sacramento CA 95826. You must personally sign the Notice
      of Claim. We will send any Notice of Claim to your registered email
      address and ATTN: NOTICE OF CLAIM to any billing address and/or shipping
      address you have provided us. The Notice of Claim sent by either party
      must include the initiating party’s name, address, phone number, email
      address, a description of the Claim (including any relevant account names,
      order numbers, items purchased, and services used), the dates and manner
      of access to The Bookroad upon which the Claim is based, and the specific
      resolution being sought. A Notice of Claim will only be valid if it
      pertains to, and is on behalf of, a single individual. If requested by the
      party that receives the Notice of Claim, the other party must personally
      appear at and participate in a video conference to discuss the Claim (if a
      party is represented by counsel, counsel may also participate). During the
      videoconference, you and The Bookroad agree to participate in a good
      faith, direct discussion about the facts, circumstances, and potential
      resolution of the Claim.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If the Claim is not resolved within Informal Resolution period of sixty
      (60) days (which period can be extended by agreement of the parties), you
      or The Bookroad may start an arbitration in accordance with this Agreement
      (or, at the option of either party, commence a proceeding in small claims
      court).
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You and The Bookroad agree that this informal dispute resolution procedure
      is a condition precedent to initiating any arbitration or filing any claim
      against the other party.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Claims Subject to Binding Arbitration; Exceptions
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Except for individual disputes that qualify for small claims court, all
      Claims, whether based in contract, tort, statute, fraud,
      misrepresentation, or any other legal theory, that are not resolved
      through informal dispute resolution as described in Section 13(a) will be
      resolved by a neutral arbitrator through final and binding arbitration
      instead of in a court by a judge or jury. Such Claims include disputes
      arising out of or relating to interpretation or application of this
      arbitration provision, including the enforceability, revocability, or
      validity of the arbitration provision or any portion of the arbitration
      provision. The arbitrator will have the authority to grant any remedy or
      relief that would otherwise be available in court.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      c. Federal Arbitration Act
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This Agreement affects interstate commerce, and the enforceability of this
      Section 13 will be substantively and procedurally governed by the Federal
      Arbitration Act, 9 U.S.C. § 1, et seq., to the extent permitted by law. As
      limited by the FAA, this Agreement, and the JAMS Rules (as defined below),
      the arbitrator will have exclusive authority to make all procedural and
      substantive decisions regarding any dispute and to grant any remedy that
      would otherwise be available in court, including the power to determine
      the question of arbitrability.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      d. Arbitration Procedure
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      All Claims must be submitted to JAMS and will be resolved through binding
      arbitration before one arbitrator. JAMS administers arbitration pursuant
      to JAMS’ Comprehensive Arbitration Rules and Procedures or pursuant to
      JAMS' Streamlined Arbitration Rules and Procedures (“
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      JAMS Rules
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      ”). The JAMS Rules are available online a
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(127, 3, 83)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      t
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(127, 3, 83)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;
    </span>
    <a href="http://www.jamsadr.com/" style={{ textDecoration: "none" }}>
      <span
        style={{
          fontSize: "10.5pt",
          fontFamily: "Roboto, sans-serif",
          color: "rgb(127, 3, 83)",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantAlternates: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fontVariantPosition: "normal",
          textDecoration: "underline",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        www.jamsadr.com
      </span>
    </a>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(127, 3, 83)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      .
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You or The Bookroad may commence arbitration by filing a written Demand
      for Arbitration with JAMS and providing a copy to the other party. The
      party commencing arbitration shall (i) include in their demand details
      about the claim sufficient to identify their interactions and transactions
      with the other party (including dates and transaction amounts) in a manner
      that is specific to the party (and thus not a superficial or generic
      statement of the claim of the sort that may be applicable to any number of
      claimants) and (ii) attach the Notice of Claim and any response
      thereto.&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(25, 26, 27)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The parties shall be responsible for their own attorneys' fees and costs
      in arbitration, unless the arbitration rules and/or applicable law provide
      otherwise.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      YOU AND THE BOOKROAD AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL
      TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS
      ARE NOT PERMITTED, AND YOU AND THE BOOKROAD ARE AGREEING TO GIVE UP THE
      ABILITY TO PARTICIPATE IN A CLASS ACTION. The arbitrator may conduct only
      an individual arbitration and may not consolidate more than one
      individual’s claims, preside over any type of class or representative
      proceeding, or preside over any proceeding involving more than one
      individual.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You and The Bookroad agree to cooperate to seek from the arbitrator
      protection for any confidential, proprietary, trade secret, or otherwise
      sensitive information, documents, testimony, and/or other materials that
      might be exchanged or the subject of discovery in the arbitration. You and
      The Bookroad agree to seek such protection before any such information,
      documents, testimony, and/or materials are exchanged or otherwise become
      the subject of discovery in the arbitration.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The arbitrator’s decision will follow this Agreement and will be final and
      binding. The arbitrator will have authority to award temporary, interim or
      permanent injunctive relief or relief providing for specific performance
      of this Agreement, but only to the extent necessary to provide relief
      warranted by the individual claim before the arbitrator. The award
      rendered by the arbitrator may be confirmed and enforced in any court
      having jurisdiction thereof. Notwithstanding any of the foregoing, nothing
      in this Agreement will preclude you from bringing issues to the attention
      of federal, state or local agencies and, if the law allows, they can seek
      relief against us for you.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      e. Opting Out of Arbitration
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You have the right to opt out of binding arbitration within thirty (30)
      days of the date you first accepted this Agreement by providing us with
      notice of your decision to opt-out via email at bookroadcontact@gmail.com.
      To be effective, the opt-out notice must include your full name, mailing
      address, account name, and email address. The notice must also clearly
      indicate your intent to opt out of binding arbitration. By opting out of
      binding arbitration, you are agreeing to resolve disputes in accordance
      with Section 14.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      f. Severability
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If any portion of this Section 13 is found to be unenforceable or unlawful
      for any reason, including but not limited to, because it is found to be
      unconscionable, (i) the unenforceable or unlawful provision will be
      severed from this Agreement; (ii) severance of the unenforceable or
      unlawful provision will have no impact whatsoever on the remainder of this
      Section 13 or the parties’ ability to compel arbitration of any remaining
      claims on an individual basis pursuant to this Section 13; and (iii) to
      the extent that any claims must therefore proceed on a class, collective,
      consolidated, or representative basis, such claims must be litigated in a
      civil court of competent jurisdiction and not in arbitration. The
      litigation of those claims will be stayed pending the outcome of any
      individual claims in arbitration. Further, if any part of this Section 13
      is found to prohibit an individual claim seeking public injunctive relief,
      that provision will have no effect to the extent such relief is allowed to
      be sought out of arbitration, and the remainder of this Section 13 will be
      enforceable.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      14. Governing Law and Venue
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This Agreement and any disputes between you and us will be governed by
      Utah law, and any disputes not subject to arbitration must be filed in the
      courts located in Salt Lake County, Utah.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This Agreement and all Claims will be governed by the laws of the State of
      California without giving effect to its principles regarding conflicts of
      law. If any Claim is not subject to arbitration pursuant to Section 13,
      then the state and federal court located in Salt Lake County, Utah will
      have exclusive jurisdiction over the Claim. You and The Bookroad waive any
      objection to venue in any such courts. Notwithstanding the foregoing,
      Claims that qualify for small claims court in the county where you live
      may be brought in such small-claims courts. You acknowledge that the
      Service originates from the United States and agree to comply with all
      export laws and regulations of the United States.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      15. Disputes with Other Users
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You will be responsible for your interactions with other Users, and you
      release The Bookroad from any liability or responsibility arising out of
      disputes with other Users.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We encourage Users to resolve disputes with one another amicably. You are
      solely responsible for your interactions with other Users, including any
      purchase or sale transactions. The Bookroad has no obligation to resolve
      disputes between Users, but The Bookroad reserves the right to attempt to
      help Users resolve disputes. You release The Bookroad from any claims,
      demands, and damages arising out of any disputes with other Users.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      16. Suspending, Terminating, or Modifying your Access&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You may terminate your Account at any time. We may suspend or terminate
      your access to the Service or your Account at our discretion, including if
      we suspect you are engaging in fraud, abuse, or illegal activity.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Termination of your Account by You
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You may terminate your Account at any time.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Suspension or Termination of your Account by Us
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We may suspend or terminate your Account at our discretion without
      explanation, notice, and liability to The Bookroad, including removing and
      discarding any Items or Content within the Service, for any reason and at
      any time. Any suspected fraudulent, abusive, or illegal activity may be
      referred to appropriate law enforcement authorities.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      c. Suspension, Termination, or Modification of the Service
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad may suspend, terminate, or modify the Service with or without
      notice and for any reason, including (i) to hold funds, pending any
      investigations or other activity necessary to deter fraud, (ii) to address
      potential illegal activity, or (iii) to otherwise comply with applicable
      laws and regulations.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      d. Effect of Suspension or Termination
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Service will not be accessible or available to Users whose Accounts
      have been temporarily or permanently suspended or terminated. Any attempts
      to circumvent any suspensions, terminations, or other enforcement actions
      by The Bookroad are strictly prohibited and may result in additional
      actions against you and any other users facilitating such circumvention.
      This includes, but is not limited to, creating new or duplicative accounts
      or continuing to access the Service from another User’s account.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad will have no liability to you or any third party for any
      suspension or termination of your Account or for any suspension,
      termination, or modification of the Service.&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      17. Changes to This Agreement
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We may update this Agreement at any time by posting updated Terms. You
      will be responsible for reviewing any updated Terms. If you do not agree
      to the updated Terms, then you may not continue to use the Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We may update this Agreement, and all related Policies, from time to time
      by posting updated Terms on the Service. When we make changes to the
      Terms, we will update the above “Last Updated” date. We may provide you
      additional notice of the changes through the Service or through email.
      Changes will be effective upon posting unless otherwise stated. You are
      responsible for reviewing the changes, and your continued use of the
      Service constitutes your acceptance of the updated Terms and the amendment
      of the Agreement. If you do not agree to abide by these or any updated
      Terms, you must not use or access the Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      18. Miscellaneous
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This section describes certain other rights and responsibilities you have
      under this Agreement.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      a. Trade and Economic Sanctions and Export Controls
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Service may be subject to economic and trade sanctions and export
      control laws in your applicable jurisdiction. You agree that you will not
      violate such laws and that you will be solely responsible for any such
      violation to the extent permitted by law. You represent that neither you,
      any business you represent, nor any beneficial owner of you or your
      business are: (i) a citizen or resident of a geographic area in which
      access to or use of the Service is prohibited by applicable law, decree,
      regulation, treaty, or administrative act; (ii) a citizen or resident of,
      or located in, a geographic area that is subject to U.S. or other
      sovereign country sanctions or embargoes; or (iii) an individual, or an
      individual employed by or associated with an entity, identified on the
      U.S. Department of Treasury Specially Designated Nationals or Blocked
      Persons Lists, or otherwise ineligible to receive items subject to U.S.
      export control laws and regulations or other economic sanction rules of
      any sovereign nation. You agree that if your country of residence or other
      circumstances change such that the above representations are no longer
      accurate, that you will immediately cease using the Service.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      b. Electronic Communication and Documentation
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      When you use the Service or send us communications, you agree to
      communicate with us electronically. This Agreement and any other documents
      referenced in the Agreement will be considered a “writing” or “in writing”
      to comply with applicable legal requirements and will be legally
      enforceable between the parties. Printed versions of this Agreement and
      the referenced documents will be admissible in any legal proceeding. For
      clarity, mere use of the Service does not constitute electronic
      communication.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      c. Severability and No Waiver
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The unenforceability of any provision of this Agreement will not, except
      as stated in Section 13(f), affect the enforceability of any other
      provision. If any provision is found to be void, invalid, or
      unenforceable, then it, except as stated in Section 13(f), will be revised
      and interpreted to accomplish the objectives of such provision to the
      extent possible under applicable law, and the remaining provisions will
      continue in full force and effect. Our non-enforcement of any provision of
      this Agreement or right under applicable law will not be construed as our
      waiver of any enforcement rights under the same or different circumstances
      at any time in the future. We reserve all rights under applicable law.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      d. Headings and Interpretation&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The headings and sub-headings in this Agreement are included for reference
      purposes only and will not affect the meaning or interpretation of this
      Agreement in any way. The words “include,” “includes,” or “including” will
      be deemed to be followed by “without limitation.” This Agreement will be
      construed without regard to any presumption or rule requiring construction
      or interpretation against the drafting party.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      19. Terms Specific to Your Geographic Location
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If you are a User in California, then certain provisions apply with regard
      to applicable laws.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Notwithstanding anything to the contrary in the Agreement, the following
      takes precedence with respect to Users in California.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      California Civil Code
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Under California Civil Code Section 1789.3, Users from California are
      entitled to the following specific consumer rights notice: The Complaint
      Assistance Unit of the Division of Consumer Services of the California
      Department of Consumer Affairs may be contacted in writing at 1625 North
      Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916)
      445-1254 or (800) 952-5210.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.2",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      20. Our Notices to You and Contact Information&nbsp;
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      This section describes how and where&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      The Bookroad
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "italic",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      &nbsp;and you may contact each other.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Notices
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      You agree that we will provide notices and messages to you within the
      Service or if required, via email or regular mail. You may provide The
      Bookroad notice, and such notice will be deemed provided once received by
      The Bookroad, addressed via mail to the address noted in the below
      “Contact Us” section.
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 700,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Contact Us
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textAlign: "start",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Please contact us with any questions or comments about the Service at:
      bookroadcontact@gmail.com
    </span>
  </p>
  <p
    dir="ltr"
    style={{
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      orphans: "auto",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: "auto",
      wordSpacing: 0,
      WebkitTextStrokeWidth: 0,
      textDecoration: "none",
      caretColor: "rgb(0, 0, 0)",
      color: "rgb(0, 0, 0)",
      lineHeight: "1.38",
      textAlign: "justify",
      marginTop: "8pt",
      marginBottom: "8pt"
    }}
  >
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Arial, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If you have any questions about these Terms or would like to report a
      violation of these Terms, please contact us at&nbsp;
    </span>
    <span
      style={{
        fontSize: "10.5pt",
        fontFamily: "Roboto, sans-serif",
        color: "rgb(0, 0, 0)",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariantLigatures: "normal",
        fontVariantCaps: "normal",
        fontVariantAlternates: "normal",
        fontVariantNumeric: "normal",
        fontVariantEastAsian: "normal",
        fontVariantPosition: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      bookroadcontact@gmail.com.
    </span>
  </p>
  <p>
    <br
      style={{
        fontStyle: "normal",
        fontVariantCaps: "normal",
        fontWeight: 400,
        letterSpacing: "normal",
        orphans: "auto",
        textAlign: "start",
        textIndent: 0,
        textTransform: "none",
        whiteSpace: "normal",
        widows: "auto",
        wordSpacing: 0,
        WebkitTextStrokeWidth: 0,
        textDecoration: "none",
        caretColor: "rgb(0, 0, 0)",
        color: "rgb(0, 0, 0)"
      }}
    />
  </p>
  <p>
    <br
      style={{
        fontStyle: "normal",
        fontVariantCaps: "normal",
        fontWeight: 400,
        letterSpacing: "normal",
        orphans: "auto",
        textAlign: "start",
        textIndent: 0,
        textTransform: "none",
        whiteSpace: "normal",
        widows: "auto",
        wordSpacing: 0,
        WebkitTextStrokeWidth: 0,
        textDecoration: "none",
        caretColor: "rgb(0, 0, 0)",
        color: "rgb(0, 0, 0)"
      }}
    />
  </p>
  <p>
    <br />
  </p>
  <p />
</>

  
  );
}

export default LegalTerms;
