import React from "react";
import {
  AdjustmentsVerticalIcon,
  ChartPieIcon,
  GlobeAmericasIcon,
  BoltIcon,
  PuzzlePieceIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = [
    {
      title: "Sign Up",
      description:
        "Sign up for an account✨",
      imageUrl: "/images/signup.png",
      icon: UsersIcon,
      iconColor: "pink",
    },

    {
      title: "Catalog",
      description:
        "View our catalog and request 📦",
        imageUrl: "/images/catalog.png",
         icon: PuzzlePieceIcon,
      iconColor: "orange",
    },
    {
      title: "List", //Give
      description: "List your books and share ❤️",
        //"Add your own books to share with the community ❤️ ",
        imageUrl: "/images/share1.jpg",
         icon: GlobeAmericasIcon,
      iconColor: "blue",
    },

  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
         // subtitle={props.subtitle}
         // strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5">
        {features.map((feature, index) => (
            <div
              className="group p-5 transition ease-out duration-200 rounded-2xl text-center"
              key={index}
            >
              <FeatureIcon2
                color={feature.iconColor}
                size="large"
                className="mb-12"
                image={feature.imageUrl}
                index={index} // Pass index here

              />
              <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
              <p className="leading-relaxed text-black">
              {/*<p className="leading-relaxed text-gray-600">*/}
                {feature.description}
              </p>
            </div>
          ))}


        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection;
