import React from "react";
import {
  CubeTransparentIcon,
  HeartIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection2(props) {
  const items = [
    {
      title: "Our Story",
      description:
      "The Book Road was born from a love for physical books, a commitment to protecting the planet, and a passion for the world's diverse languages and cultures.",

       // "The Book Road was born from a passion for reading and a commitment to making great books accessible to all.",
      icon: HeartIcon,
      iconColor: "text-red-500",
    },
    {
      title: "COMMITMENT",
      description:
        "We are committed to making great books more accessible while also making a positive impact on the planet.",

       // "We are committed to our work and stand by our projects. Our aim is to continually improve them and offer the best experience to our community.",
      icon: ArrowDownIcon,
      iconColor: "text-blue-500",
    },
    {
      title: "Mission",
      description:
      "Our mission is to build a vibrant community around the love for sharing books, sustainability, and language-learning.",

        //"To provide opportunities that empower individuals to obtain current and diverse books in foreign languages.",
      icon: CubeTransparentIcon,
      iconColor: "text-pink-500",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
<div className="space-y-16 container">
  <div>
    <CubeTransparentIcon className="text-blue-600 mb-5 inline-block w-16 h-16" />
    <SectionHeader
      title={props.title}
      subtitle={props.subtitle}
      strapline={props.strapline}
      className="lg:w-1/2"
    />
  </div>


  <div className="text-gray-700 space-y-6">
    <p className="leading-relaxed text-xl"> 
      I created this site to share books (English and Foreign Language) with others. If you're fond of this idea, please share and join us! 
    </p>


    <p className="leading-relaxed text-xl">
    Connect with me at bookroadcontact@gmail.com! 
    </p>
 

  </div>


  <div className="flex space-x-2">
    <div> 
      <img src={props.leftImage} width="1350" height="800" alt="" className="rounded-lg" />
    </div>
    <div>
      <img src={props.rightImage} width="600" height="800" alt="" className="rounded-lg" />
    </div>
  </div>

 


   {/* Adding the three paragraphs here 
   
       I love reading physical books, including foreign language books that are often only available physically through online stores, because reading in a language is fundamental to learning the language, its culture, and its values. 

   <div className="text-gray-700 space-y-6">
    <p className="leading-relaxed text-xl">
    I love reading books and books in foreign languages. Foreign language books are a special case because it’s usually necessary for individuals to buy the book they want to read since they’re not as readily available in libraries or bookstores. Over the years,  I’ve developed a deep connection with physical books and generally prefer them over other formats.

    </p>
    <p className="leading-relaxed text-xl">

    I created The Book Road because I realized I would love to share the books I loved reading and find recycled books more easily. I imagine that others would also like giving their books a new life and bringing joy to people who they know would enjoy them. If this sounds interesting to you or you have something else to share, feel free to reach out! I’m always excited to meet new people and hear their perspectives.

    </p>
 

  </div>

  <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
    {items.map((item, index) => (
      <div key={index}>
        <h3 className="flex items-center space-x-2 text-lg font-bold uppercase tracking-wide mb-2">
          <item.icon
            className={
              "inline-block w-5 h-5" +
              (item.iconColor ? ` ${item.iconColor}` : "")
            }
          />
          <span>{item.title}</span>
        </h3>
        <p className="leading-relaxed text-gray-600">
          {item.description}
        </p>
      </div>
    ))}
  </div>
*/}
</div>

    </Section>
  );
}

export default HeroSection2;
