// SelectField.js
import React from 'react';

const SelectField = ({ id, name, placeholder, options, defaultValue, error, inputRef, ...rest }) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {placeholder}
      </label>
      <select
        id={id}
        name={name}
        defaultValue={defaultValue}
        ref={inputRef}
        className={`p-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md mt-1 block w-full`}
        {...rest}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <p className="text-red-500 text-sm">{error.message}</p>
      )}
    </div>
  );
};

export default SelectField;
