import React from "react";

function LegalPrivacy(props) {
  return (
<>
  <p style={{ textAlign: "center" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}>
        Privacy Policy
      </span>
    </strong>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Effective Date:
      </span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;July 1, 2024
    </span>
  </p>
  <p>
    <br />
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We strive to protect the privacy of our users and any personal data shared
      with us. This Privacy Policy describes the types of personal data The
      Bookroad and our subsidiaries and affiliates (collectively “
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        The Bookroad
      </span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>”, “</span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>we</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>”, “</span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>us</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>”, and “</span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>our</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ”) obtain; how we may collect, use, and share it; and how you may exercise
      your rights and choices with respect to that data.&nbsp;
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      For California residents, view our California Privacy Notice at the bottom
      of this Privacy Policy.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        1. Scope
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      This Privacy Policy applies to personal data we obtain when users (“
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>you</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>” or “</span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>your</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ”) interact with The Bookroad services, products, tools, and features
      (collectively “
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Services
      </span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ”) through the use of our websites, mobile applications, or on any other
      medium used to access our Services and through other data collection
      points that reference this Privacy Policy.&nbsp;
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We may provide additional information about our privacy practices at other
      points, for example, where this will help us provide more relevant and
      timely information.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        2. What Data We Collect
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We collect personal data about you when you provide it to us, when you use
      our Services, and when other sources provide it to us, as further
      described below.
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Information You Provide to Us Directly:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We collect information about you when you provide it directly to us as
          part of our Services, such as when you register for an account, set
          genres, languages, and other preferences, and participate in any
          interactive feature, survey, promotion, or event. This information may
          include your name, address, phone number, email, username, language,
          gender, age, genre preferences, and any other personal data you may
          provide.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Information You Provide when Using Our Services and Interacting with
            Other Users:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We collect information and content when you create book listings,
          participate in a anonymous book exchange, make book requests, search
          for items, and comment or otherwise interact with listings, or other
          users. This includes any pictures, video and audio clips, closet
          descriptions, search terms, information about items you have listed,
          sent, or received, and any interaction with users and listings, such
          as likes, comments, replies, and offers. When you send or receive on
          The Bookroad, we will need to collect payment and billing information,
          such as credit card and bank account information to complete the
          transaction.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(67, 71, 78)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Information You Provide through Our Support Channels:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We collect information when you contact The Bookroad and our customer
          support for any reason, such as asking questions and sharing feedback,
          issues, or problems. You may be asked to provide contact information,
          a summary of the problem you are experiencing, and any other
          documentation, screenshots or information that would be helpful in
          resolving an issue.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(67, 71, 78)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Information You Provide when Verifying Your Identity:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Identity verification may be required for fraud prevention and
          compliance purposes. We may collect certain identification
          information, such as driver’s license information, social security
          number, and passport number to verify your identity. We may also ask
          to verify your identity using photo verification.&nbsp;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Information We Collect Automatically when You Use the
            Services:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We collect information about you when you use our Services, including
          when browsing or taking certain actions within the Services. This
          includes information about the features you may use, links or pages
          you click on, pages viewed, search and browsing history, IP address
          and device data (e.g. operating system, version, model, identifiers,
          etc.), referring and exit pages, and other analytics data about your
          usage or our Services.
        </span>
        <em>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Cookies and other tracking technologies:
          </span>
        </em>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We, our service providers, and other third-party partners may collect
          information such as device data, IP address, hashed emails,
          transaction data (such as order value) and online activity data
          through automated means such as cookies, web beacons, software
          development kits (SDKs), tracking URLs, and similar technologies.
          Cookies are used to store and receive identifiers and other
          information, such as the name of the domain from which the cookie
          originated and when the cookie expires. Cookies and similar
          technologies may enable The Bookroad, as well as parties other than
          The Bookroad, to collect information through our Services.We and the
          companies we work with may use cookies and similar technologies to
          improve the functionality and user experience of our Services, gather
          and store information about user preferences and past actions, perform
          analytics, detect fraud, measure our ad conversion and performance,
          optimize our ad campaigns, customize your experience, and bring you
          ads through interest-based advertising. Interest-based advertising
          enables us to provide you with more relevant advertising about
          products and services based on the information we and other parties
          may collect about your online activities over time and across
          different websites. For example, you can learn how Google collects and
          uses information when we use their services (including Google
          Analytics) and how Connexity and Taboola use cookies and similar
          technologies to optimize ads and recommend content to you.Most
          browsers will allow you to control cookies through the browser
          settings. View your browser developer’s support center, help menu, or
          website to learn whether or how you can manage cookies on your
          browser. Please note that, without cookies, you may not be able to use
          some or all of the features on or functionality of our Services.Our
          Service currently does not respond to “Do Not Track” (DNT) signals.To
          find out more about “Do Not Track,” please visit
          http://www.allaboutdnt.com.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Information We Collect from Social Media Platforms:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          You can connect your social media or other third-party account to your
          The Bookroad account, such as Facebook, Twitter, Instagram, Pinterest,
          or Snapchat. If you choose to connect your social media accounts to
          our Services, we may collect information about you from these
          accounts, such as username, friend or following lists, and other
          profile information.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Information about Others:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Prior to sharing any information about others for any purpose through
          the Service, you must satisfy any consents or other applicable legal
          and regulatory requirements. This includes, but is not limited to, the
          information or images you provide about any third parties on comments,
          or listing.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        3. How We Use Your Data
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We use the information we collect to provide, develop, enhance our
      Services, and for other business purposes needed to deliver our Services
      or as required for legal or compliance purposes. This includes to:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Personalize your experience.&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          For example, we use your information, including profile, search,
          likes, and exchange histories, to suggest items, offers, features, or
          events that we think you might enjoy. We may use the data collected
          through cookies and other tracking technologies (if enabled) to
          remember information so that you will not have to re-enter it during
          your visit or the next time you visit the site and to provide custom,
          personalized content and information.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Process and fulfill orders.&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          This includes sharing your contact and shipping information with
          sellers and our shipping partners.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Provide you with customer support, communications, updates,
            promotions, announcements, and other news about relevant features
            and aspects of our Services.&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          For example, we may send you communications responding to customer
          support questions, providing information about transactions, or
          notifying about user engagement. You may adjust your notification
          preferences through our app or website, or follow any opt-out
          instructions provided with the communication (e.g. at the bottom of an
          email).
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Improve safety, security, and integrity and prevent fraud.
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;This includes investigating suspicious activity, preventing the
          sale of counterfeit items, and fulfilling anti-money laundering and
          know-your-customer obligations. We use information about you to verify
          accounts and activity, to monitor suspicious or fraudulent activity,
          and to identify violations of our Services’ terms and policies.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Enable social features such as connecting you with other users.
          </span>
        </strong>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Provide, analyze and improve the Services.&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          This also includes research and development and creation of new
          features and may involve creating anonymous and aggregated data.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            For advertising and marketing purposes.&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Depending on your location and any applicable rights or choices you
          may have exercised, this may include providing you with advertising
          and marketing related to our Services, measuring our ad performance
          and conversion, optimizing our ad campaigns, and showing you
          personalized and non-personalized ads on or off of the Services.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Where we believe it is necessary to fulfill or protect our legal
            rights, interests, and protect the interests of others.&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          This includes complying with relevant laws, regulation, or other
          obligations in connection with legal claims, audit functions, an
          acquisition, merger, or sale of a business.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            With your consent:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We may use information about you where you have given us consent to do
          so for a specific purpose not listed above.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        4. How We Share the Information We Collect
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We share your information as described below:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Provide the Services:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;We will share your information with companies that provide a
          service to us or our users, such as shipping, identity verification,
          fraud monitoring, payment processing, storage, database maintenance,
          analytics, and hosting. For example, we may use Google Maps API to
          help autocomplete and validate your address.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Offers, Ads, and Promotions:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We may share your information with partners and affiliates, which may
          include brands or other third parties, as well as advertising networks
          and related providers for advertising, marketing, and promotional
          purposes.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Complete Transactions:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;We may share information with others users when you make a
          purchase or a sale or otherwise conduct transactions on the Service.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Sharing Feedback:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;We may share feedback we receive from you. This may include, but
          is not limited to love notes, comments on listings, and survey
          responses. This feedback may be attributed to you, for example, with
          your username.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Law Enforcement or Legal Requests:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;We may share information about you with a third party if we
          believe that sharing is reasonably necessary to comply with the law
          and the reasonable requests of law enforcement, to enforce our Terms
          of Service or to protect the security or integrity of our Service, or
          to exercise or protect the rights, property, or personal safety of The
          Bookroad, our users, or others.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Business Transaction:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We may transfer the personal data we have about you in the event of a
          potential or actual sale or transfer of all or a portion of our
          business or assets (including in the event of a merger, acquisition,
          joint venture, reorganization, divestiture, dissolution, or
          liquidation), or other business transaction.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            With your consent:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We may also share information about you with third parties when you
          give us consent to do so.
        </span>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We may also share aggregated and anonymous information, including
      demographic info, survey results, market trends, and other analysis that
      we create based on the information we receive.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      In addition, please note that we are not responsible for the personal data
      you choose to submit on pages and content accessible to the general
      public, including but not limited to, your profile, closet, listings
      (including any “Meet the Posher” listings), photos, comments (including on
      our blog), love notes, Posh Stories, or Posh Shows. Once displayed on a
      publicly accessible location, that information can be collected, used, and
      shared by the public at large both on and off our Services (e.g. a listing
      could be shared to social media).
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        5. How We Store and Maintain the Information We Collect
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The information we obtain will be retained to provide the Services to you
      and to fulfill the business and commercial purposes for which they are
      collected or as needed to satisfy applicable governmental laws,
      regulations, investigations, proceedings or for reasonable backup,
      archival, audit, legal, tax, accounting, compliance, or other similar
      purposes. For example, requirements related to financial transactions and
      anti-money laundering purposes often require us to keep certain
      information for 7 years but may vary depending on your location.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Security:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad employs organizational, technical, and physical safeguards
      intended to protect personal data from loss, misuse, and unauthorized
      access, disclosure, alteration, or destruction. Depending on the
      circumstances, our safeguards to protect personal data may include
      facilities access restrictions, system access controls, firewalls, data
      encryption, and other security best practices.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      No internet, email, or other electronic transmission is ever fully secure
      or error free, however, so you should take special care in deciding what
      information you send to us in this way. Your information may be stored
      with third-party cloud service infrastructure providers.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        International Data Transfers:
      </span>
    </strong>
  </p>
  <p>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We are headquartered in the United States and may share your information
      with service providers and other recipients in the United States and
      worldwide. If you are located in a region with laws governing data
      collection and use that may differ from U.S. law, please note that we may
      transfer personal data to a country and jurisdiction that does not have
      the same data protection laws as your jurisdiction. We use appropriate
      transfer mechanisms where required.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        6. Legal Basis for Processing Your Information
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad may collect and process your information on the basis of
      different legal grounds, depending on the nature of the information being
      provided, the type of processing involved, and your location of residence,
      as follows:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Performance of a contract:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;By using our Services or otherwise purchasing or selling on our
          platform, you are agreeing to our Terms of Service which constitutes a
          valid legal contract. We process your data in order to fulfill any
          obligations, such as completing transactions between you and other
          users and you and The Bookroad.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Legitimate interest:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;Such legitimate interests will include where The Bookroad sends
          you marketing about our products and services where we believe you
          have a reasonable expectation that we will perform a particular type
          of processing on your behalf or where such processing is necessary for
          fraud detection and prevention. The Bookroad will only rely on such a
          ground where an assessment has been performed balancing the interests
          and rights involved and the necessity of the processing in order to
          provide our services, products, and features to you.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Legal obligations:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;Where the processing is necessary for compliance with a legal
          obligation.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Consent:
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          &nbsp;Finally, in certain situations, The Bookroad relies on your
          consent in order to process your Personal Data such as marketing
          communications.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        7. Your Rights and Choices
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Depending on your location, you may have certain rights, choices, and
      means available to you when it comes to your information, including the
      use and disclosure of that data. Below is a summary of the choices you may
      have and how to exercise them.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Access, Correct, or Delete Your Personal Data:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      You may access, correct, or delete your personal data in your account
      settings. When you make a request, we may need information from you to
      help us confirm your identity and verify your right to access, correct, or
      delete your personal data (or to exercise any of your other rights), and
      may charge you a fee for such request, where allowable. Please note, if
      you delete your information, your account will be terminated.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Your requests and choices may be limited in certain cases, such as the
      following examples: if fulfilling your request would reveal information
      about another person, or if you ask to delete information which we are
      required by law or have compelling legitimate interests to keep.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Withdraw Consent:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Where allowed by law, you may withdraw such consent at any time; however,
      withdrawing such consent may limit or restrict your use or access of our
      Services. Please direct your request to&nbsp;
    </span>
    <a href="mailto:bookroadcontact@gmail.com">
      <u>
        <span style={{ color: "rgb(17, 85, 204)", fontSize: "10.5pt" }}>
          bookroadcontact@gmail.com
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;unless a different means of making the request is listed in a
      region-specific section.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Region-Specific Information:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <em>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        California Residents
      </span>
    </em>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The California Consumer Privacy Act (“
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>CCPA</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ”) requires that we provide California residents with additional
      information about their rights and our information practices. If you are a
      California resident, please review our&nbsp;
    </span>
    <span
      style={{
        color: "rgb(0, 0, 0)",
     //   backgroundColor: "rgb(255, 255, 0)",
        fontSize: "10.5pt"
      }}
    >
      California Privacy Notice
    </span>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      , which supplements this Privacy Policy and details your California
      privacy rights
    </span>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>.</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <em>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Colorado, Connecticut, Utah, and Virginia Residents
      </span>
    </em>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      You have the right to opt out of (i) the “sale” of your personal
      information, (ii) targeted advertising, and (iii) profiling in furtherance
      of decisions that produce legal or similarly significant effects. You may
      request to opt out by contacting&nbsp;
    </span>
    <a href="mailto:bookroadcontact@gmail.com">
      <u>
        <span style={{ color: "rgb(17, 85, 204)", fontSize: "10.5pt" }}>
          bookroadcontact@gmail.com
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>.</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      You also have the right to request, with some exceptions, to obtain a copy
      of your personal information in a portable format, to confirm whether we
      are acting as a controller when processing your personal information, to
      correct inaccuracies, and to delete your information. If a request you
      made to exercise your rights access is denied, you may appeal the denial
      by writing to bookroadcontact@gmail.com
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The categories of personal information we process are in Section 2 above.
      The purposes for processing that information are in Section 3 above. The
      categories of information that we share with third parties and the
      categories of third parties we share that information with is in Section 4
      above.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        8. Additional Information
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Depending on your location, you may have certain rights, choices, and
      means available to you when it comes to your information, including the
      use and disclosure of that data. Below is a summary of the choices you may
      have and how to exercise them.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Links to External Content:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Services may also include links that direct you to other websites or
      services whose privacy practices may differ from ours. If you submit
      information to any of those third-party sites, your information is
      governed by their privacy policies, not this one. We encourage you to
      carefully read the privacy policy of any website you visit.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Children's Privacy:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad does not knowingly collect or solicit any information from
      anyone under the age of 13 through this Service. If you believe that a
      child under 13 years of age may have provided us with personal data,
      please contact us at bookroadcontact@gmail.com.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Your Acceptance of this Privacy Policy:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      By accessing or using our Services, you signify that you have read,
      understood, and agree to our collection, storage, use, and disclosure of
      your personal data as described in this Privacy Policy. If you do not
      agree, you should discontinue use of the Services immediately.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Your Acceptance of this Privacy Policy:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      By accessing or using our Services, you signify that you have read,
      understood, and agree to our collection, storage, use, and disclosure of
      your personal data as described in this Privacy Policy. If you do not
      agree, you should discontinue use of the Services immediately.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Changes to this Privacy Policy:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We reserve the right to revise this Privacy Policy from time to time. We
      will post changes on this page and indicate the “Effective Date” at the
      top of this page. Please check back often for any updates. You acknowledge
      and agree that it is your responsibility to review this Privacy Policy
      periodically and that your continued use of our Services after any change
      in this Privacy Policy will constitute your acceptance of such change.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Contact Us:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      If you have any questions about this Privacy Policy, you may contact us at
      bookroadcontact@gmail.com
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p style={{ textAlign: "center" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}>
        California Privacy Notice
      </span>
    </strong>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Effective Date:
      </span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;July 1, 2024
    </span>
  </p>
  <p>
    <br />
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      This California Privacy Notice (“
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>Notice</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ”) supplements our&nbsp;
    </span>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Privacy Policy
    </span>
    <span style={{ color: "rgb(67, 71, 78)", fontSize: "10.5pt" }}>&nbsp;</span>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      and applies to the information we may collect (or may have collected in
      the 12-month period preceding the effective date) about users who are
      California residents (“
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>you</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>”, “</span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>your</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ”), including if you attended a The Bookroad event. Please note that this
      Notice does not cover information we have collected from individuals in
      their capacity as an employee or job applicant.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        1. Notice at Collection
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      This section of our California Privacy Notice serves as our California
      “Notice at Collection” and describes the categories of personal
      information we collect, the retention of such information, the sources
      from which the information was obtained, and the business and commercial
      purposes for which the information is used. It also details the categories
      of personal information “sold” or “shared” with third parties for
      cross-context behavioral advertising and disclosed to third parties for a
      business purpose.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Categories of Personal Information We Collect:
      </span>
    </strong>
  </p>
  <p>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We may collect the following categories of personal information about you:
    </span>
  </p>
  <div align="left">
    <table style={{ border: "medium", borderCollapse: "collapse" }}>
      <tbody>
        <tr>
          <td
            style={{
              //color: "rgb(226, 235, 242)",
              backgroundColor: "#fde047"
            }}
          >
            <p>
              <strong>
                <span
                  style={{fontSize: "10.5pt" }}
                >
                  Category
                </span>
              </strong>
            </p>
          </td>
          <td
            style={{
             // color: "rgb(226, 235, 242)",
              backgroundColor: "#fde047"
            }}
          >
            <p>
              <strong>
                <span
                  style={{ fontSize: "10.5pt" }}
                >
                  Personal Information this Category Includes
                </span>
              </strong>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Identifiers
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p style={{ textAlign: "justify" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Identifiers, such as real name, alias, postal address, unique
                personal identifier (e.g., device identifiers; cookies, beacons,
                pixel tags, mobile ad identifiers, or similar technology;
                customer number, unique pseudonym, or user alias; telephone
                number and other forms of persistent or probabilistic
                identifiers), online identifier, IP address, email address,
                account name, Social Security number, driver’s license number,
                state identification card number, passport number, or other
                similar identifiers.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Personal Records
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p style={{ textAlign: "justify" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Signature, physical characteristics or description (such as any
                size preferences you may have set), education (college and class
                year), bank account number, credit card number, debit card
                number, and other financial information
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Protected Classifications
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p style={{ textAlign: "justify" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Characteristics of protected classifications under California or
                federal law, such as age and gender.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Commercial Information
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p style={{ textAlign: "justify" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Commercial information, including records of personal property,
                products or services purchased, obtained, or considered, and
                other purchasing or consuming histories or tendencies.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Online Activity
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p style={{ textAlign: "justify" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Internet and other electronic network activity information
                including, but not limited to, browsing history, search history,
                and information regarding your interaction with websites,
                applications or advertisements.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Audiovisual Information
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p style={{ textAlign: "justify" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Audio, electronic, visual, and similar information, such as your
                photos, posts, and other content you generate.
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Inferences
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p style={{ textAlign: "justify" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Inferences drawn from any of the information identified above to
                create a profile about you reflecting your preferences,
                characteristics, psychological trends, predispositions,
                behavior, attitudes, intelligence, abilities, and aptitudes.
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Retention of Personal Information:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The categories of personal information we obtain will be retained to
      provide the Services to you and to fulfill the business and commercial
      purposes for which they are collected or as needed to satisfy applicable
      governmental laws, regulations, investigations, proceedings or for
      reasonable backup, archival, audit, legal, tax, accounting, compliance, or
      other similar purposes. To determine the retention period, we may consider
      the amount, nature, and sensitivity of the information, our business and
      compliance needs, the potential risk of harm from unauthorized use or
      disclosure of information and if we can attain our objectives by other
      means, as well as our legal, regulatory, tax, accounting, and other
      applicable obligations.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Sources of Personal Information:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We may obtain this information directly from you or your devices, or from
      other categories of sources, such as from social networks, data analytics
      providers, marketing partners, advertising and affiliate networks,
      operating systems and platforms, internet service providers, and
      government entities. For additional detail, please review the “What
      Information We Collect About You” section of our Privacy Policy.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Purposes for which Personal Information is Used:
      </span>
    </strong>
  </p>
  <p>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      As described in additional detail in the “How We Use Your Data” section of
      our Privacy Policy, we may use this information for our business and
      commercial purposes to provide, develop, and enhance our Services to you.
      This may include using the information for the following business
      purposes:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Personalize your experience;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Process and fulfill orders;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Personalize your experience;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Short-term, transient use;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Provide you with customer support, communications, updates,
          promotions, announcements, and other news about relevant features and
          aspects of our Services;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Enable social features, such as connecting you with other users;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Provide you with advertising and marketing related to our Services;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Auditing related to counting ad impressions to unique visitors,
          verifying positioning and quality of ad impressions, and auditing
          compliance;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Undertake activities to verify, maintain, or improve the quality,
          safety, security, and integrity of our Services and to improve,
          upgrade, or enhance them;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Undertake activities to identify and repair errors that impair
          existing intended functionality (i.e. debugging);
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Conduct internal research for technological development and
          demonstration; and
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Where we believe it is necessary, fulfill or protect our legal rights,
          interests, and protect the interests of others.
        </span>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        “Sale” or “Sharing” of Personal Information for Cross-Context Behavioral
        Advertising:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We may disclose certain of your personal information to third parties,
      such as online advertising services (like advertising and affiliate
      networks) and our marketing partners, in exchange for monetary or
      non-monetary consideration (hereafter referred to as the “
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>Sale</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ” of personal information) or for cross-context behavioral advertising
      (hereafter referred to as the “
    </span>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>Sharing</span>
    </strong>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ” of personal information).
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The personal information we may Sell or Share to these parties include
      Identifiers, Protected Classifications, Commercial Information, and Online
      Activity. This helps us optimize the placement of our ads and serve more
      relevant advertisements, including on other websites and services.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We do not Sell or Share the personal information of California residents
      we know to be under the age of 16 without affirmative authorization.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Disclosures of Personal Information for a Business Purpose:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The table below lists the types of third parties to whom we may have
      disclosed personal information for a business purpose and the categories
      of personal information that may have been disclosed to them for a
      business purpose.
    </span>
  </p>
  <div align="left">
    <table style={{ border: "medium", borderCollapse: "collapse" }}>
      <tbody>
        <tr>
          <td
            style={{
             // color: "rgb(226, 235, 242)",
              backgroundColor: "#fde047"
            }}
          >
            <p>
              <strong>
                <span
                  style={{fontSize: "10.5pt" }}
                >
                  Category of Third Parties
                </span>
              </strong>
            </p>
          </td>
          <td
            style={{
             // color: "rgb(226, 235, 242)",
              backgroundColor: "#fde047"
            }}
          >
            <p>
              <strong>
                <span
                  style={{ fontSize: "10.5pt" }}
                >
                  Categories of Personal Information Disclosed
                </span>
              </strong>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Other users of the Service, if you ask us to
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Identifiers, Commercial Information
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Vendors (e.g., to help process and fulfill orders, improve
                safety, security, and integrity, prevent fraud, provide
                non-personalized advertising, and perform analytics)
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Identifiers, Personal Records, Protected Classifications,
                Commercial Information, Online Activity, Audiovisual
                Information, Inferences
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Professional services organizations, such as auditors and law
                firms
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Identifiers, Personal Records, Protected Classifications,
                Commercial Information, Online Activity, Audiovisual
                Information, Inferences
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Business partners, such as those with whom we run promotions
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Identifiers, Protected Classifications, Commercial Information,
                Online Activity
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Law enforcement authorities, regulators and other government
                entities
              </span>
            </p>
          </td>
          <td style={{ color: "rgb(226, 235, 242)" }}>
            <p>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
                Identifiers, Personal Records, Protected Classifications,
                Commercial Information, Online Activity, Audiovisual
                Information, Inferences
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      In addition, we may disclose and may have disclosed your personal
      information to third parties in the event of a corporate transaction, such
      as a merger or acquisition.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        2. Your California Privacy Rights
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Under the CCPA, you have certain rights and choices regarding your
      personal information, as described below:&nbsp;
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Right to Know (Access):&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          You have the right to request, twice in a 12-month period, that we
          disclose to you the personal information related to you that we have
          collected, used, disclosed, and sold during the past 12 months, as
          well as the specific pieces of personal information we have collected
          about you during the past 12 months.&nbsp;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Right to Correct:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          You have the right to request that we correct inaccurate personal
          information about you. To do so, please email
          bookroadcontact@gmail.com.
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Right to Delete:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          You have the right to request that we delete certain of the personal
          information we have collected from you.&nbsp;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Right to Opt Out of Sale or Sharing:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          You have the right to opt-out of the Sale or Sharing of your personal
          information by The Bookroad. You may request to opt out here by
          emailing &nbsp;bookroadcontact@gmail.com.&nbsp;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <strong>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
            Right to Limit:&nbsp;
          </span>
        </strong>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          We do not use or disclose sensitive personal information for the
          purpose of “inferring characteristics” about you, except as otherwise
          expressly permitted under the CCPA.
        </span>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      If you choose to exercise any of your rights under the CCPA, you have the
      right not to receive discriminatory treatment by us. Please note that,
      where allowable, we may charge a reasonable fee to comply with your
      request.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Verifying CCPA Rights Requests:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      As required by law and to help protect your privacy, we may take steps to
      verify your identity when you submit a request to exercise your rights.
      The steps we take may vary depending on the type of request you make and
      whether you have an account with us. Please note that we cannot process
      your request if you do not provide us with sufficient detail to allow us
      to understand and respond to it.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      If you have an account with us, we may verify your identity by requiring
      you to sign into your account. If you request to access, correct, or
      delete your personal information and do not have an account with us, we
      may require you to provide certain information (such as your name and
      email address) to verify your identity. In addition, if you do not have an
      account and you ask us to provide you with specific pieces of personal
      information, we reserve the option to require you to sign a declaration
      under penalty of perjury that you are the consumer whose personal
      information is the subject of the request.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Authorized Agents:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      To submit a request as an authorized agent on behalf of a consumer, please
      email us at bookroadcontact@gmail.com, and be sure to reference the CCPA
      in your request and include proof that the consumer has provided
      permission for you, the authorized agent, to submit the request.
    </span>
  </p>
  <p>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Please note that, for requests submitted by an authorized agent on behalf
      of a consumer, we may still require the consumer to verify their own
      identity directly with us.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        3. Additional Information
      </span>
    </strong>
  </p>
  <p>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Your Acceptance of this Privacy Policy:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      By accessing or using our Services, you signify that you have read,
      understood, and agree to our collection, storage, use, and disclosure of
      your personal data as described in this Privacy Policy. If you do not
      agree, you should discontinue use of the Services immediately.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Changes to this Notice:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      We reserve the right to revise this Privacy Notice from time to time. We
      will post changes on this page and indicate the “Effective Date” at the
      top of this notice. Please check back often for any updates. You
      acknowledge and agree that it is your responsibility to review this
      Privacy Notice periodically and that your continued use of our Services
      after any change in this Privacy Notice will constitute your acceptance of
      such change.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        How to Contact Us:
      </span>
    </strong>
  </p>
  <p>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      If you have any questions about this Notice, please contact us at
      bookroadcontact@gmail.com
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
</>

  );
}

export default LegalPrivacy;
