import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        title="Get in touch"
        subtitle="Thank you for your interest in The Book Road! We’re excited to hear from you and help with any questions or feedback you may have. Feel free to contact us at bookroadcontact@gmail.com and we will get back to you as soon as possible."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage="/images/bg2.jpg"
        bgImageOpacity={0.8}
        textColor=""
      />
    </>
  );
}

export default ContactPage;
