import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import Button from "./Button";




function DashboardSection(props) {
  const auth = useAuth();

  const openGoogleForm = () => {

    //1FAIpQLSfc2ySfgzUqbMpbHEYu5wQAeQaW8cuFm350mgVfDSwl5vXOaQ

    const formId = process.env.YOUR_FORM_ID;
    //window.open(`https://docs.google.com/forms/d/e/${formId}/viewform`, '_blank');  };
    window.open(`https://docs.google.com/forms/d/e/1FAIpQLSfc2ySfgzUqbMpbHEYu5wQAeQaW8cuFm350mgVfDSwl5vXOaQ/viewform`, '_blank');  
  };
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">
          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-gray-200">
              <DashboardItems />
            </div>
          </div>
          <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h3 className="mb-4">How it works?</h3>
           {/* <h3 className="mb-4">Book Swap</h3>*/}

            <div>
            <div>
              <strong>Step 1: </strong> 
              Click "Add Book" to start adding books you want to give out. 
            </div>
            <div>
              <strong>Step 2: </strong> 
              Go to Book Catalog to view our current collection and request books you like. 
            </div>
            {/*
            <div>
              <strong>Step 3:</strong>
              To request a book, simply email us the book title at bookroadcontact@gmail.com.

                            Each book shipped to you will have a <strong>$10</strong>  fee.          

            </div> */}
 
        

            <h3 className="mb-4">Fees</h3>
            <div>
            This website is completely free to use. Feel free to list your books and share them with your friends! 
            </div>

             {/*

             All books are $10 to cover shipping and platform fees.

             <h3 className="mb-4">Rewards Program</h3>
            <div>
              <strong>List 3 Books, Get 50% Off Next Book: </strong> 
              For every 3 books you add to the catalog, you receive a 50% discount on your next book.
            </div>
            <div>
              <strong>Buy 3 Books, Get 1 Free: </strong> 
              For every 3 books you buy, you get 1 additional book for free.
            </div>
            <div>
              <strong>50% Off for Each Shipped Book: </strong> 
              For every book you ship out, you'll receive a 50% discount on your next book.
            </div>
            <div>
              <strong>Refer a Friend: </strong> 
              When someone joins the app using your referral code <strong>"{auth.user.referralCode}"</strong>, both you and the new user receive a 50% discount on a book!
            </div>
*/}

            </div>
{/*

              <h3 className="mb-4">Book Pal</h3>
              <Button
                size="sm"
                variant="primary"
                onClick={openGoogleForm} // Open Google Form on button click
              >
                Start
              </Button>

              
              <div>
                Anonymous Book Exchange
              <div>
                <strong>Step 1</strong>.
                Click the “Start” button above.
              </div>
              <div>
                <strong>Step 2</strong>.
                Enter your information. Once your information is processed, it will appear under Submissions. 
              </div>
              <div>
                <strong>Step 3</strong>.
                We will contact you with details on shipping your book.
              </div>
              <div>
                <strong>Step 4</strong>.
                Ship your book. 
              </div>
              <div>
                <strong>Step 5</strong>.
                Receive your new book in the mail. 
              </div>
              
              </div>
              <p>
              Book Pal is a program that helps connect people across cities, across countries, 
              and across continents with books. We’re dedicated to fostering memorable moments through the sharing of books. 
              You can exchange books in a foreign language or trade books in your own language. 

              </p>
*/}

              {/*
              <p>
              We started this program because foreign books are often challenging to find and obtain.
              We know there are many book lovers, language learners, and foreign 
              nationals that would love to read interesting, unique, and diverse books that are 
              only available in booksstores far, far away.
             
              </p>

              <p>
              Why not share an awesome book you've found with a book from someone else's collection?
              You will both be surprised by a delightful book hand-picked by a native speaker. 
              </p>
*/}
            {/*
              <h3 className="mb-4">Extra info</h3>
              <div>
                You are signed in as <strong>{auth.user.email}</strong>.
              </div>

              {auth.user.stripeSubscriptionId && (
                <>
                  <div>
                    You are subscribed to the{" "}
                    <strong>{auth.user.planId} plan</strong>.
                  </div>
                  <div>
                    Your plan status is{" "}
                    <strong>{auth.user.stripeSubscriptionStatus}</strong>.
                  </div>
                </>
              )}

              <div>
                You can change your account info{` `}
                {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                in{` `}
                <Link to="/settings/general">settings</Link>.
              </div>

           
              {!auth.user.stripeSubscriptionId && (
                <div>
                  You can signup for a plan in{" "}
                  <Link to="/pricing">pricing</Link>.
                </div>
              )}
            */}  
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default DashboardSection;
