import React, { useState } from "react";
import { useRouter } from "./../util/router"; // Ensure this is correctly imported
import { useAuth } from "./../util/auth";
import { updateUser } from "./../util/db";


const generateReferralCode = () => {
  return Math.random().toString(36).substring(2, 8).toUpperCase();
};



function OnboardingPage() {
  const router = useRouter(); // Use your router hook
  const auth = useAuth();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    age: "",
    gender: "",
    bookGenres: "",
    location: "",
    referral: "",
    referralCode: generateReferralCode() // Add the referral code to formData

  });

  const handleChange = (e) => {
    const { name, value } = e.target; // Use name instead of firstname
    setFormData({
      ...formData,
      [name]: value // Update formData based on input's name
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitData = new FormData();
    submitData.append("entry.530811197", formData.firstname); // Replace XXXXXX with the field ID for firstname
    submitData.append("entry.1543284480", formData.lastname); // Replace XXXXXX with the field ID for lastname
    submitData.append("entry.1634871397", auth.user.email); // Replace XXXXXX with the field ID for email
    submitData.append("entry.760175045", formData.location); // Replace XXXXXX with the field ID for lastname
    submitData.append("entry.1312549047", formData.referral); // Replace XXXXXX with the field ID for lastname

    try {
      await fetch("https://docs.google.com/forms/d/e/1FAIpQLSf1EnGLdsM_B2WAd_SsZmQMrqTf4o_W4hyGEhlQQBIcHtizzg/formResponse", {
        method: "POST",
        body: submitData,
        mode: "no-cors",
      });
      await updateUser(auth.user.uid, formData);
      router.push("/dashboard"); // Navigate to dashboard on successful submission
    } catch (error) {
      console.error("Error updating user: ", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  return (
    <div className="container max-w-md mx-auto py-12">
      <h1 className="text-2xl mb-4">Personalization</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="firstname">First Name</label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="lastname">Last Name</label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="age">Age</label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="non-binary">Non-binary</option>
            <option value="prefer-not-to-say">Prefer not to say</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div>
          <label htmlFor="bookGenres">What genres of books do you like to read?</label>
          <input
            type="text"
            id="bookGenres"
            name="bookGenres"
            value={formData.bookGenres}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="location">Which city do you live in?</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="referral">Got a referral code? Enter it here!</label>
          <input
            type="text"
            id="referral"
            name="referral"
            //value={formData.referralCode}
            onChange={handleChange}
            //required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        
        <button type="submit" className="w-full p-2 bg-yellow-500 text-white rounded">Submit</button>
      </form>
    </div>
  );
}

export default OnboardingPage;
