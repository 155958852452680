import React, { useState } from "react";
import {
  StarIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import FormAlert from "./FormAlert";
import Button from "./Button";
import EditItemModal from "./EditItemModal";
import { useAuth } from "./../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "./../util/db";

function DashboardItems(props) {
  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth.user.uid);


  const [creatingItem, setCreatingItem] = useState(false);
  const [updatingItemId, setUpdatingItemId] = useState(null);

  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarItem = (item) => {
    if (canUseStar) {
      updateItem(item.id, { featured: !item.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };


  const handleDeleteClick = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this item?");
    if (confirmed) {
      deleteItem(id);
    }
  };

  //console.log("items:",items)

  return (
    <>
      {itemsError && (
        <div className="mb-4">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}
      

      <div>
  
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <span className="text-xl">Your Books</span>
          <Button
            size="sm"
            variant="primary"
            onClick={() => setCreatingItem(true)}
          >
            Add Book
          </Button>
          
        </div>

        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <div className="p-8">
            {itemsStatus === "loading" && <>Loading...</>}

            {itemsStatus !== "loading" && itemsAreEmpty && (
              <>Your books will appear here and on the Catalog page.</>
            )}
          </div>
        )}

        {itemsStatus !== "loading" && items && items.length > 0 && (
          <>
            {items.map((item, index) => (
              <div
                className={
                  "flex p-4 border-b border-gray-200" +
                  (item.featured ? " bg-yellow-200" : "")
                }
                key={index}
              >
                {item.title}
                
                <div className="flex items-center ml-auto space-x-3">
                  {/*
                  <button
                    className={
                      "w-5 h-5" +
                      (item.featured ? " text-yellow-500" : "") +
                      (!item.featured ? " text-slate-600" : "")
                    }
                    onClick={() => handleStarItem(item)}
                  >
                    <StarIcon />
                  </button> */}
                  <button
                    className="w-5 h-5 text-slate-600"
                    onClick={() => setUpdatingItemId(item.id)}
                  >
                    <PencilSquareIcon />
                  </button>
                  
                  <button
                    className="w-5 h-5 text-slate-600"
                    onClick={() => handleDeleteClick(item.id)} // Pass a function reference
                  >
                    <TrashIcon />
                  </button> 
                </div>
              </div>
            ))}
          </>
        )}

      </div>

      {creatingItem && <EditItemModal onDone={() => setCreatingItem(false)} />}

      {updatingItemId && (
        <EditItemModal
          id={updatingItemId}
          onDone={() => setUpdatingItemId(null)}
        />
      )}
    </>
  );
}

export default DashboardItems;
