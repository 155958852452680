import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaCatalogSection from "./../components/CtaCatalogSection";
import NewsletterSection from "./../components/NewsletterSection";
import StatsSection from "./../components/StatsSection";

//import Button from "./Button";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        title="The Book Road"
        subtitle="Community for trading books online"
        //"Online library for books in all languages"
        //"Helping readers share and exchange Chinese books" //around the world.
       //Shared online library for physical book lovers
        //subtitle="Focus on building your amazing service and we will do the rest. We grew more than 10,000 online businesses."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />



      <FeaturesSection
        title="How It Works"
        //subtitle="Build user friendly and modern dashboards using the latest tech. Now is the time with a best selling UI framework."
        //strapline="Simple Steps to Get Started"
        size="md"
        bgColor="bg-amber-400"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />

      <StatsSection
       // title="Loved by Readers"
        subtitle="Join users from across the U.S. to share and exchange books!"
       // subtitle="Join users from across the U.S. who are discovering and sharing books on our platform!"

        //strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      {/*
      eventually, want to add both of these 1) schools 2) reviews/comments
      <ClientsSection
        title="You're in good company"
        subtitle=""
        strapline="Lots of happy customers"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <TestimonialsSection
        title="Customer Testimonials"
        subtitle=""
        strapline="Real Feedback"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />

                  Create an account <span className="text-yellow-600">today</span>!

      */}
      <CtaCatalogSection
        title={
          <>
            Ready to<span className="text-white"> find</span> your next <span className="text-white">book</span>?
          </>
        }
       // subtitle="Explore our collection now and enjoy the joy of sharing books!"
        strapline=""
        size="md"
        bgColor="bg-amber-400"
        bgImage=""

       // bgImage="/images/planet2.png"
        bgImageOpacity={1}
        textColor=""
      />
      <NewsletterSection
        title="Subscribe to our newsletter"
        //subtitle="Join us and receive the best curated news, freebies and resources directly to your inbox every week!"
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default IndexPage;
