import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />

      <HeroSection2
        title={
          <>
            Hi! I'm <span className="font-light">Audrey</span>
          </>
        }
        //subtitle="A passionate team who are here to help you have best experience exchanging books."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        //src = "/images/happyreader.jpg"
        //leftImage = "/images/booksgrass.jpg" //h = 800 w = 1280
       // rightImage = "/images/earth2.jpg"//h = 800 q=90 w=600
       leftImage = "/images/earth1.jpg" //h = 800 w = 1280
        rightImage = "/images/share1.jpg"//h = 800 q=90 w=600
        //leftImage="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?crop=entropy&fit=crop&h=800&w=1280"
        //rightImage="https://images.unsplash.com/photo-1554232456-8727aae0cfa4?crop=entropy&fit=crop&h=800&q=80&w=600"
      />
   
   <StatsSection
        //title="Loved by Readers"
        subtitle="Join users from across the U.S. who are discovering and sharing books on our platform!"
        //strapline="Inspiring Results"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
         {/*
      <TeamBiosSection
        title="Meet our amazing team"
        subtitle="They are working nonstop behind the scenes to help you build better products, web services and websites."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />


                  Create an account <span className="text-blue-600">today</span>!

      */}
      <CtaSection
        title={
          <>
            Create an account <span className="text-blue-600">today</span>!
          </>
        }
        subtitle="Give your books a new life and find unique and awesome books!"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        //bgImage = "/images/bg1.jpg"

        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
