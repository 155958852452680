import React, { useState } from 'react';
import { useAllItems } from './../util/db'; // Import your custom hook
import Section from './../components/Section';
import SectionHeader from './../components/SectionHeader';
import Button from './../components/Button';
import ViewDetailsButton from './../components/ViewDetailsButton';

import './CatalogPage.css';

// Comprehensive list of languages
const languages = [
  { value: '', label: 'All Languages' },
  { value: 'English', label: 'English' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'French', label: 'French' },
  { value: 'German', label: 'German' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Traditional Chinese', label: 'Traditional Chinese' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Swahili', label: 'Swahili' },
  // Add more languages as needed
];

// Comprehensive list of genres
const genres = [
  { value: '', label: 'All Genres' },
  { value: 'Adventure', label: 'Adventure' },
  { value: 'Biography', label: 'Biography' },
  { value: 'Children’s', label: 'Children’s' },
  { value: 'Classics', label: 'Classics' },
  { value: 'Comics', label: 'Comics' },
  { value: 'Crime', label: 'Crime' },
  { value: 'Drama', label: 'Drama' },
  { value: 'Fantasy', label: 'Fantasy' },
  { value: 'Historical', label: 'Historical' },
  { value: 'Horror', label: 'Horror' },
  { value: 'Fiction', label: 'Fiction' },
  { value: 'Literary Fiction', label: 'Literary Fiction' },

  { value: 'Mystery', label: 'Mystery' },
  { value: 'Non-Fiction', label: 'Non-Fiction' },
  { value: 'Romance', label: 'Romance' },
  { value: 'Science Fiction', label: 'Science Fiction' },
  { value: 'Thriller', label: 'Thriller' },
  { value: 'Travel', label: 'Travel' },
  { value: 'Young Adult', label: 'Young Adult' },
  { value: 'Poetry', label: 'Poetry' },
  { value: 'Psychology', label: 'Psychology' },
  { value: 'Self-Help', label: 'Self-Help' },
  { value: 'LGBT', label: 'LGBT' },
  { value: 'Cooking', label: 'Cooking' },
  { value: 'Humor', label: 'Humor' }



];

function CatalogPage() {
  const { data: items = [], isLoading, error } = useAllItems(); // Default items to an empty array
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDifficulty, setSelectedDifficulty] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');

  //const [selectedLanguage, setSelectedLanguage] = useState('Chinese');

  // Filter items based on the search query and selected filters
  const filteredItems = items.filter(item => {
    const query = searchQuery.toLowerCase();
    const matchesSearch = item.title?.toLowerCase().includes(query) || item.author?.toLowerCase().includes(query);
    const matchesLanguage = !selectedLanguage || item.language === selectedLanguage;
    const matchesDifficulty = !selectedDifficulty || item.difficulty === selectedDifficulty;
    const matchesGenre = !selectedGenre || item.genre === selectedGenre;
    
    return matchesSearch && matchesLanguage && matchesDifficulty && matchesGenre;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching items: {error.message}</div>;
  }


 
  return (
    <Section
      size="full"
      bgColor="#f9f9f9"
      textColor="#333"
    >
      <div className="container">
        <SectionHeader
          title="Catalog"
          subtitle="Explore the items available in our catalog"
          //strapline="Explore the items available in our catalog."
          className="text-center"
        />

        {/* Search Bar */}
        <div className="mb-4 flex flex-col md:flex-row md:justify-between">
          <input
            type="text"
            placeholder="Search by title or author..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2 border border-gray-300 rounded-md mb-4 md:mb-0 md:w-1/3"
          />

          {/* Filters */}
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="select-container">
              <select
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                className="p-2 border border-gray-300 rounded-md mb-4 md:mb-0"
              >
                {languages.map((lang) => (
                  <option key={lang.value} value={lang.value}>
                    {lang.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="select-container">
              <select
                value={selectedDifficulty}
                onChange={(e) => setSelectedDifficulty(e.target.value)}
                className="p-2 border border-gray-300 rounded-md mb-4 md:mb-0"
              >
                <option value="">All Difficulties</option>
                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Hard">Hard</option>
                <option value="Very Hard">Very Hard</option>

              </select>
            </div>

            <div className="select-container">
              <select
                value={selectedGenre}
                onChange={(e) => setSelectedGenre(e.target.value)}
                className="p-2 border border-gray-300 rounded-md"
              >
                {genres.map((genre) => (
                  <option key={genre.value} value={genre.value}>
                    {genre.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>


        <div className="flex flex-wrap -mx-4">
  {filteredItems.map(item => (
    <div key={item.id} className="p-4 w-full md:w-1/3 lg:w-1/4">
      <div className="rounded border border-gray-200 p-4 bg-white shadow-md">
        <img
          src={item.imageUrl || '/images/book_placeholder.jpg'}
          alt={item.title || 'Item Image'}
          className="w-full h-48 object-cover mb-4"
        />
        
        <h2 className="text-lg font-semibold">{item.title}</h2>
        <p className="text-gray-600">{item.author}</p>
        <p className="text-gray-600">{item.language}</p>
        {/* 
        <p className="text-gray-600">{item.difficulty}</p>
        <p className="text-gray-600">{item.genre}</p>
        Add any additional item details here



        <p className="text-gray-600">
          Price: ${item.price !== undefined ? item.price : '5'}
        </p>

      
        */}

        

        <ViewDetailsButton item={item} />
        {/*
        <Button
          text="View Details"
          onClick={() => window.open(`/item/${item.id}`, '_blank')}
        />
        */}
      </div>
    </div>
  ))}
</div>

      </div>
    </Section>
  );
}

export default CatalogPage;
