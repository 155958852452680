import React from "react";

function ShippingTerms(props) {
  return (
<>
  <p> </p>
  <p style={{ textAlign: "center" }}>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "12pt"
        }}
      >
        Shipping Policy
      </span>
    </strong>
  </p>

  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      The Bookroad will not provide any prepaid shipping labels. The Bookroad
      will&nbsp;
    </span>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      provide shipping instructions to Senders. Sender’s shipping fees will be
      reimbursed based on the price shown on the shipping label, up to $15. For
      shipping fees exceeding $15, reimbursement is at the sole discretion of
      the company.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Receivers are responsible for a $10 fee to cover shipping costs and any
      additional fees. The Bookroad reserves the right to adjust this fee in the
      future. If the shipping cost exceeds $10, The Bookroad may request an
      increase in the item price to cover the additional cost.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "10.5pt"
        }}
      >
        User Responsibilities
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      Receivers must pay for any shipping fees as described in our Fee Policy,
      Receiver Policy, or as otherwise described in this Shipping Policy.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      Sender will be fully responsible for contents of shipment and must comply
      with the The Bookroad Terms of Service, including Prohibited Items Policy
      and terms and conditions of the shipping carrier.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      Sender must ship the Item(s) to the Receiver within seven (7) days from
      the date the order was purchased (as verified by an active shipping
      carrier scan). If Sender does not ship the Item(s) within this timeframe,
      Receiver can cancel the order, and The Bookroad will notify the Sender not
      to proceed with shipment.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      Receivers and Senders must maintain an accurate and up to date ship to /
      ship from address with The Bookroad. The Bookroad reserves the right to
      charge a User additional fees if a User does not meet any of these
      obligations.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      Users are prohibited from using Label for any purpose other than shipping
      the purchased item(s) to the Receiver. This includes, without limitation,
      altering Label from its original form or duplicate use of the same Label.
    </span>
  </p>
  <p>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "10.5pt"
        }}
      >
        Shipping Issues
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      Receiver must report any post-delivery (defined as delivery scan is
      present as determined by the carrier tracking information) issues
      (including, but not limited to Unable to locate shipment, Undisclosed
      damage, Incorrect or missing item, Item not as described, Item is not
      authentic) to The Bookroad within three (3) days after the date of
      delivery scan. Once Receiver confirms receipt and acceptance of Item(s)
      (acceptance can occur either by Receiver affirmatively notifying The
      Bookroad or by taking no action within three (3) days of the date of
      delivery), The Bookroad will credit Sender’s account with amount equal to
      purchase price, excluding taxes, The Bookroad’s commission, and any
      applicable fees (the “
    </span>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "10.5pt"
        }}
      >
        Funds
      </span>
    </strong>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      ”) set forth in our Fee Policy.
    </span>
  </p>
  <p>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      The Bookroad is not liable for damages due to improper or inadequate
      packaging by Sender.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      Please note, we cannot guarantee shipping times or choice of carriers.
      While most The Bookroad carriers offer shipping with an estimated transit
      time of 3 days, The Bookroad is not responsible for carrier delays, which
      may be caused by a number of factors, such as weather.
    </span>
  </p>
  <p>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "10.5pt"
        }}
      >
        Overweight Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      The Bookroad reserves the right to refuse packages over 5 lbs without an
      upgraded label and any packages over 10 lbs and will not reimburse
      additional postage paid for packages that exceed 10 lbs.
    </span>
  </p>
  <p>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "10.5pt"
        }}
      >
        Oversize Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      As part of shipping carrier policy, the maximum combined length and girth
      of any box used must be no greater than 24". The Bookroad reserves the
      right to charge Senders for not complying.
    </span>
  </p>
  <p>
    <strong>
      <span
        style={{
          color: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255, 255, 255)",
          fontSize: "10.5pt"
        }}
      >
        Additional Requirements
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      For any avoidance of a doubt, User is responsible to ship in compliance
      with&nbsp;
    </span>
    <a href="https://pe.usps.com/text/pub52/welcome.htm">
      <u>
        <span
          style={{
            color: "rgb(127, 3, 83)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "10.5pt"
          }}
        >
          USPS Publication 52 - Hazardous, Restricted, and Perishable Mail
        </span>
      </u>
    </a>
    <span
      style={{
        color: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "10.5pt"
      }}
    >
      .
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <hr />
  <p>
    <br />
  </p>
  <p style={{ textAlign: "center" }}>
    <span
      style={{
        color: "rgb(67, 71, 78)",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "8.5pt"
      }}
    >
      Copyright © 2024,&nbsp;
    </span>
    <a href="https://poshmark.com/">
      <u>
        <span
          style={{
            color: "rgb(127, 3, 83)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "8.5pt"
          }}
        >
          The Bookroad
        </span>
      </u>
    </a>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
</>

  );
}

export default ShippingTerms;
