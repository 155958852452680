import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useItem } from "./../util/db";
import { useAuth } from "./../util/auth";

function ItemDetailPage() {
  const { id } = useParams();
  const [itemData, setItemData] = useState(null);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [message, setMessage] = useState("");
  const { data, status } = useItem(id);
  const auth = useAuth();

  useEffect(() => {
    if (status === "success") {
      setItemData(data);
    }
  }, [status, data]);

  const handleRequestClick = () => {
    setShowRequestForm(true);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleRequestSubmit = async (event) => {
    event.preventDefault();

    // Prepare the data to be submitted
    const formData = new FormData();
    formData.append("entry.779021761", auth.user.email); // Replace XXXXXX with the field ID for email
    formData.append("entry.707426345", id); // Replace XXXXXX with the field ID for item ID
    formData.append("entry.446031407", itemData.title); // Replace XXXXXX with the field ID for title
    formData.append("entry.692535801", itemData.author); // Replace XXXXXX with the field ID for author
    formData.append("entry.1352807444", itemData.language); // Replace XXXXXX with the field ID for language
    formData.append("entry.1345617739", itemData.ownerEmail); // Replace XXXXXX with the field ID for owner's email
    formData.append("entry.2032181845", message); // Replace XXXXXX with the field ID for owner's email

    try {
      await fetch("https://docs.google.com/forms/d/e/1FAIpQLSdcL_M6c8sqVL-297rQ45PCHIZ2X11C3bLuo-pCTMqzUmLt7w/formResponse", {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });

      setMessage("");
      setShowRequestForm(false);
      alert("Request submitted successfully!");
    } catch (error) {
      console.error("Error submitting request:", error);
      alert("There was an error submitting your request.");
    }
  };

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "error") {
    return <div>Error fetching item data</div>;
  }

  if (!itemData) {
    return <div>No item found</div>;
  }

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h1 className="text-2xl font-bold">{itemData.title}</h1>
      <img
        src={itemData.imageUrl || '/images/book_placeholder.jpg'}
        alt={itemData.title || 'Item Image'}
        className="w-full h-auto object-cover rounded-md"
      />
      <p><strong>Author:</strong> {itemData.author}</p>
      <p><strong>Language:</strong> {itemData.language}</p>
      <p><strong>Genre:</strong> {itemData.genre}</p>
      <p><strong>Difficulty:</strong> {itemData.difficulty}</p>
      <p><strong>Location:</strong> {itemData.location}</p>

      <button
        onClick={handleRequestClick}
        className="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-md"
      >
        Request
      </button>

      {showRequestForm && (
        <form onSubmit={handleRequestSubmit} className="mt-4 p-4 border rounded-md bg-gray-100">
          <textarea
            value={message}
            onChange={handleMessageChange}
            placeholder="Optional message"
            rows="4"
            className="w-full p-2 border rounded-md"
          />
          <button
            type="submit"
            className="mt-2 px-4 py-2 bg-yellow-500 text-white rounded-md"
          >
            Confirm
          </button>
        </form>
      )}
    </div>
  );
}

export default ItemDetailPage;
