// ViewDetailsButton.js
import { useState } from 'react';
import { useAuth } from './../util/auth';
import Button from './Button';
import Modal from './Modal'; // Correct import

const ViewDetailsButton = ({ item }) => {
  const { user } = useAuth(); // Get current user
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    if (user) {
      window.open(`/item/${item.id}`, '_blank');
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button
        text="View Details"
        onClick={handleClick}
      />
      {showModal && (
        <Modal onClose={handleCloseModal}>
          <h2>Please Sign In</h2>
          <p>You need to sign in to view the details. <a href="/auth/signup">Sign Up</a></p>
        </Modal>
      )}
    </>
  );
};

export default ViewDetailsButton;
