import React from "react";

function ProhibitedTerms(props) {
  return (
    //<div className="prose prose-a:text-blue-600 max-w-none">
<>
  {/* Hello world */}
  <p> </p>
  <p style={{ textAlign: "center" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "12pt" }}>
        Prohibited Items Policy
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad’s marketplace welcomes the sale of most types of new and
      pre-owned books. For the safety of our users, items outside of our
      supported categories and otherwise prohibited or unlawful items may not be
      offered for sale on The Bookroad. We reserve the right to remove items and
      content under this policy, or any other The Bookroad policies and Terms of
      Service, at our sole discretion.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      If you list items on The Bookroad, you have a critical role in preventing
      illegal and potentially unsafe items from appearing in our marketplace, so
      please be sure your items are allowed to be sent before adding them to
      your dashboard. If you list prohibited items or otherwise include them as
      part of any transaction or shipment, The Bookroad may remove them, and you
      may be subject to temporary or permanent account restrictions.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Any exchanges involving prohibited items may be canceled and The Bookroad
      reserves the right to withhold payment. The Bookroad also reserves the
      right to dispose or destroy any prohibited items that are sent to us.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      If you’re not sure which laws may apply to you, you may wish to seek
      advice from a qualified professional.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      If you see an item for giving on The Bookroad that you believe may be in
      violation of this policy, please report it to us. We will look into it and
      take any necessary actions.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        1. Restricted Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Restricted items include those that are generally allowed but must meet
      certain conditions in order to be considered in compliance with The
      Bookroad’s policies.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Electronics
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Electronic items are not allowed.&nbsp;
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Anonymous Exchanges
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Any anonymous book exchanges are permitted at the sender’s and receiver’s
      own risk. In order to offer “
    </span>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Anonymous Exchanges
    </span>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      ” and qualify for sender’s protection in the event of a dispute involving
      a mystery listing, senders must:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          disclose key details about the item(s) included in the anonymous
          exchange including: quantity, item(s) category, size(s) and condition
          of each item;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          provide a truthful assessment of the value of the item(s) in the
          anonymous exchange;&nbsp;
        </span>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        2. Prohibited Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Prohibited items are not allowed to be sold on The Bookroad, no matter how
      they are presented. Users who attempt to receive, send, or give prohibited
      or otherwise illegal items risk immediate and permanent suspension from
      The Bookroad, and other enforcement actions, such as withholding payments
      or funds.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Counterfeit and Infringing Products
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Counterfeit products are illegal to send and receive, and strictly
      prohibited on The Bookroad. Often referred to as “replicas,” counterfeit
      items may include fakes, knockoffs, bootlegs, and otherwise illegally
      replicated goods.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Make sure that you have the rights to send any items you list on The
      Bookroad. Do not upload any images that you didn't take, that are of other
      people without their permission, or that you do not have the rights to
      use. The Bookroad will promptly process and investigate notices of alleged
      infringement. See our Terms of Service for more information.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Nudity and Explicit Content
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      In order to maintain a welcoming community, sexually explicit items and
      content featuring nudity are not allowed on The Bookroad. We define nudity
      as realistic female toplessness and perceptible genitalia (of any gender),
      including minimal coverage by sheer or very tight fabric.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Hateful and Violent Content
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Everyone who uses The Bookroad deserves to feel safe and welcome in our
      community. We do not allow content that inherently expresses, encourages,
      or glorifies hate or violence towards individuals or groups based on race,
      ethnicity, immigration status, national origin, religion, gender, gender
      identity, sexual orientation, disability, weight or size.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Prohibited hateful and violent content includes but is not limited to:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Hateful speech and symbols;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Harmful stereotypes and demeaning depictions of protected groups;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Condoning the views or activities of hateful or violent groups or
          individuals;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Threatening or inciting violence towards others; and/or
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Promoting self harm or suicide.
        </span>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        3. Dangerous Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Certain types of items may present an outsized risk of harm if produced,
      maintained, or used improperly. Oftentimes there are shipping restrictions
      and, in many cases, government regulations that apply to these products,
      so for the welfare of The Bookroad buyers and sellers, items that may pose
      a hazard to health or safety are not allowed.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Be sure to check with your shipping carrier and government resources to
      learn what restrictions may apply to you. If you are not sure, you may
      wish to consult with a qualified expert in your area.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Shipping Resources:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <a href="https://www.canadapost-postescanada.ca/cpc/en/personal/sending/parcels/restrictions.page">
          <u>
            <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
              Canada Post Restrictions on Personal Shipping
            </span>
          </u>
        </a>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <a href="https://www.usps.com/ship/shipping-restrictions.htm">
          <u>
            <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
              USPS Domestic Shipping Prohibitions &amp; Restrictions
            </span>
          </u>
        </a>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Food, Drugs, and Drug Paraphernalia
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad is not the place to sell anything meant to be eaten or
      otherwise consumed by humans or animals. Do not list anything meant to be
      consumed including raw food ingredients, packaged food products,
      beverages, baby formula, and nutritional supplements.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      You are not allowed to sell illegal drugs on The Bookroad, but we also
      prohibit over-the-counter drugs, prescription drugs, alcohol, tobacco, and
      drug-related paraphernalia that is meant for making, using, or concealing
      drugs.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Hazardous Materials
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad prohibits hazardous materials, including but not limited to:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Aerosols and ozone-depleting substances;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Fuels, combustibles, and explosive precursors;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Carcinogens, poisons, and pesticides;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Radioactive materials; and/or
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Toxic plant materials.
        </span>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Knives and Weapons
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      With the exception of table knives, knives and weapons are not allowed on
      The Bookroad. Weapons accessories, parts, and designs are also not
      allowed, nor are realistic toy and replica weapons.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Medical Devices and Prescription Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Classified medical devices and prescription items are not allowed. Please
      do not list anything meant to be used to prevent, diagnose, or treat a
      medical condition. Common examples include body wraps, contact lenses, and
      breast pumps. Medical devices are prohibited regardless of whether they do
      or don’t require a prescription. If you are not sure whether your item is
      considered a medical device, you may wish to consult the&nbsp;
    </span>
    <a href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpcd/pcdsimplesearch.cfm">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          FDA Product Classification Database
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;or a similar resource from your local authority.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Recalled and Highly Regulated Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad prohibits the sale of items that have been recalled by
      governments or manufacturers. This includes items like lawn darts,&nbsp;
    </span>
    <a href="https://www.cpsc.gov/Safety-Education/Safety-Education-Centers/Magnets">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          small high-powered magnet toys
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      , certain items with&nbsp;
    </span>
    <a href="https://www.cpsc.gov/Safety-Education/Neighborhood-Safety-Network/Posters/Button-Battery-Dangers">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          button batteries
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      , and several baby products such as&nbsp;
    </span>
    <a href="https://www.cpsc.gov/Business--Manufacturing/Business-Education/Business-Guidance/Small-Parts-for-Toys-and-Childrens-Products">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          toys with small parts
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>.</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad cooperates with various external authorities to identify and
      remove items that may pose safety concerns even if they have not been
      recalled. This may include items such as baby carriers and car seats,
      infant sleeper items, and certain personal protective equipment (PPE) such
      as helmets.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Visit the Consumer Product Safety Commission’s&nbsp;
    </span>
    <a href="https://www.cpsc.gov/Recalls">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          website
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;or check with your local authority for more information on what
      items might be subject to a recall or regulation.
    </span>
  </p>
  <p>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad prohibits any item that originates from a location deemed as
      sanctioned by&nbsp;
    </span>
    <a href="https://ofac.treasury.gov/">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          The Office of Foreign Assets Control ("OFAC")
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      . Do not list or otherwise attempt to sell through The Bookroad any items
      that may have originated in the following locations:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>Cuba</span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Crimea, (KYRM) region of Ukraine
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Donetsk People’s Republic (DNR) region of Ukraine
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Luhansk People’s Republic (LNR) region of Ukraine
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Democratic People's Republic of Korea (North Korea)
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>Iran</span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>Syria</span>
      </p>
    </li>
  </ul>
  <p>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Certain items originating from Russia and Belarus are prohibited, as well
      as any item subject to the US Tariff Act or related acts.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        4. Plant and Animal Products
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Plants and Seeds
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      The Bookroad does not allow the sale of living or viable plants and seeds.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>Animals</span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Do not sell live animals, eggs, or any product derived from domestic cats
      and dogs or endangered animal species, regardless of when it was made.
      Trade of animal species listed in&nbsp;
    </span>
    <a href="https://cites.org/eng/app/appendices.php">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          Appendix I
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;of the Convention on International Trade in Endangered Species
      (CITES) is prohibited internationally.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Some examples of prohibited animal products to refrain from listing on The
      Bookroad include but are not limited to:
    </span>
  </p>
  <ul>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Elephant and mammoth ivory; commonly found in netsuke and okimono
          figurines, billiard balls, game pieces, and many kinds of jewelry;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Marine turtle shell; commonly found in bracelets, hair clips, and
          glasses;
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Pangolin leather belts and boots, commonly misidentified as anteater;
          and/or
        </span>
      </p>
    </li>
    <li
      style={{
        listStyleType: "disc",
        color: "rgb(0, 0, 0)",
        fontSize: "10.5pt"
      }}
    >
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
          Cheetah, jaguar, leopard, lion, ocelot, and tiger fur, claws, and
          teeth; commonly found in coats and jewelry.
        </span>
      </p>
    </li>
  </ul>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      For a full list of prohibited species and commonly related products, see
      the&nbsp;
    </span>
    <a href="https://www.endwildlifetraffickingonline.org/coalition-prohibited-wildlife-policy">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          Coalition to End Wildlife Trafficking Online
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>.</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Human Parts
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Human body parts, waste, fluids, and remains are not allowed for legal and
      sanitary reasons. Wigs made of human hair are allowed.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        5. Deceptive Content
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Impersonation Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Equipment and accessories that may enable someone to impersonate trusted
      figures such as current active police, military, airport, or emergency
      personnel are prohibited. This includes without limitation
      realistic-looking uniforms, badges, documents and credentials.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Medical Status Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Medical documents, badges, and other potentially deceitful medical items
      are not allowed. This includes without limitation medical test results,
      vaccination status cards, and items designating service animals.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Misrepresented American Indian and Alaska Native Arts and Crafts
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <a href="https://www.doi.gov/iacb/indian-arts-and-crafts-act-1990">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          The Indian Arts and Crafts Act
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;is a truth-in-advertising law that prohibits misrepresentation in
      the marketing of American Indian art and craft products within the United
      States. It is illegal to offer or sell any art or craft product in a
      manner that falsely suggests it is the product of Native American,
      American Indian, or Alaska Native tribes or organizations.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        Stolen Items
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Stolen merchandise cannot be listed or sold on The Bookroad as it violates
      state, federal, and international law. The Bookroad will&nbsp;
    </span>
    <a href="https://support.poshmark.com/s/article/policy-stolen-merchandise">
      <u>
        <span style={{ color: "rgb(127, 3, 83)", fontSize: "10.5pt" }}>
          cooperate with law enforcement
        </span>
      </u>
    </a>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      &nbsp;to assist with the investigation of allegedly stolen goods,
      including sharing data in accordance with our Privacy Policy.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
        6. Unsupported Content
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "rgb(0, 0, 0)", fontSize: "10.5pt" }}>
      Please do not list items outside of books and preowned books. Items
      outside of these categories are not allowed on The Bookroad, even if not
      explicitly restricted or prohibited in this Prohibited Items Policy.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
</>


  
  );
}

export default ProhibitedTerms;
