import React, { useState, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import SelectField from "./SelectField"; // Import the SelectField component
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";
import { useItem, updateItem, createItem, uploadSingleItemImage } from "./../util/db";

// Comprehensive list of languages
const languages = [
  { value: '', label: '' },
  { value: 'English', label: 'English' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'French', label: 'French' },
  { value: 'German', label: 'German' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Traditional Chinese', label: 'Traditional Chinese' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Swahili', label: 'Swahili' },
  // Add more languages as needed
];

// Comprehensive list of genres
const genres = [
  { value: '', label: '' },
  { value: 'Adventure', label: 'Adventure' },
  { value: 'Biography', label: 'Biography' },
  { value: 'Children’s', label: 'Children’s' },
  { value: 'Classics', label: 'Classics' },
  { value: 'Comics', label: 'Comics' },
  { value: 'Crime', label: 'Crime' },
  { value: 'Drama', label: 'Drama' },
  { value: 'Fantasy', label: 'Fantasy' },
  { value: 'Historical', label: 'Historical' },
  { value: 'Horror', label: 'Horror' },
  { value: 'Fiction', label: 'Fiction' },
  { value: 'Literary Fiction', label: 'Literary Fiction' },
  { value: 'Mystery', label: 'Mystery' },
  { value: 'Non-Fiction', label: 'Non-Fiction' },
  { value: 'Romance', label: 'Romance' },
  { value: 'Science Fiction', label: 'Science Fiction' },
  { value: 'Thriller', label: 'Thriller' },
  { value: 'Travel', label: 'Travel' },
  { value: 'Young Adult', label: 'Young Adult' },
  { value: 'Poetry', label: 'Poetry' },
  { value: 'Psychology', label: 'Psychology' },
  { value: 'Self-Help', label: 'Self-Help' },
  { value: 'LGBT', label: 'LGBT' },
  { value: 'Cooking', label: 'Cooking' },
  { value: 'Humor', label: 'Humor' }


];

// Define difficulty options
const difficulties = [
  { value: '', label: '' },
  { value: 'Easy', label: 'Easy' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Hard', label: 'Hard' },
  { value: 'Very Hard', label: 'Very Hard' }
];


function EditItemModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

    
  {/*
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      language: props.itemData?.language || '',
      genre: props.itemData?.genre || '',

    }

    
  });


*/}
  const { data: itemData, status: itemStatus } = useItem(props.id);

  useEffect(() => {
    if (itemData && itemData.imageUrl) {
      setImageUrl(itemData.imageUrl);
    }
  }, [itemData]);

  if (props.id && itemStatus !== "success") {
    return null;
  }

  const onSubmit = async (data) => {
    setPending(true);

    const finalData = {
      ...data,
      owner: auth.user.uid,
      ...(imageUrl && { imageUrl }), // Conditional inclusion of imageUrl
    };

    const query = props.id
      ? updateItem(props.id, finalData)
      : createItem(finalData);

    query
      .then(() => {
        props.onDone();
      })
      .catch((error) => {
        setPending(false);
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true); // Start loading indicator
      try {
        const uniqueFileName = `${new Date().getTime()}-${file.name}`;
        const downloadURL = await uploadSingleItemImage(file, uniqueFileName);
        setImageUrl(downloadURL);
      } catch (error) {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      } finally {
        setLoading(false); // Stop loading indicator
      }
    }
  };

  
  const handleImageUploadOld = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const uniqueFileName = `${new Date().getTime()}-${file.name}`;
        const downloadURL = await uploadSingleItemImage(file, uniqueFileName);
        setImageUrl(downloadURL);
      } catch (error) {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      }
    }
  };



  return (
    <Transition appear={true} show={true}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={() => props.onDone()}
      >
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {props.id ? "Update" : "Create"} Item
              </Dialog.Title>
              <div className="mt-4">
                {formAlert && (
                  <div className="mb-4">
                    <FormAlert
                      type={formAlert.type}
                      message={formAlert.message}
                    />
                  </div>
                )}

                <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Title"
                    defaultValue={itemData && itemData.title}
                    error={errors.title}
                    inputRef={register({
                      required: "Please enter a title",
                    })}
                  />
                  <TextField
                    type="text"
                    id="author"
                    name="author"
                    placeholder="Author"
                    defaultValue={itemData && itemData.author}
                    error={errors.author}
                    inputRef={register({
                      required: "Please enter an author",
                    })}
                  />
                  <SelectField
                    id="language"
                    name="language"
                    placeholder="Language"
                    options={languages}
                    defaultValue={itemData?.language || ''}
                    error={errors.language}
                    inputRef={register({
                      required: "Please select a language",
                    })}
                  />
                  
                  <SelectField
                    id="difficulty"
                    name="difficulty"
                    placeholder="Difficulty"
                    options={difficulties}
                    defaultValue={itemData?.difficulty || ''}
                    error={errors.difficulty}
                    inputRef={register({
                      required: "Please select a difficulty level",
                    })}
                  />
                  
                  <SelectField
                    id="genre"
                    name="genre"
                    placeholder="Genre"
                    options={genres}
                    defaultValue={itemData?.genre || ''}
                    error={errors.genre}
                    inputRef={register({
                      // No validation rules, so genre is optional
                    })}
                  />
                  <TextField
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Location (city, country)"
                    defaultValue={itemData && itemData.location}
                    error={errors.location}
                    inputRef={register({
                      required: "Please enter a location",
                    })}
                  />

                  <TextField
                    type="text"
                    id="price"
                    name="price"
                    placeholder="Price (optional)"
                    defaultValue={itemData && itemData.price}
                    error={errors.price}
                    inputRef={register()}
                  />

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      {imageUrl ? "Replace Image" : "Upload Image (optional)"}
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="mt-1 block w-full"
                    />
                  {loading && (
                    <div
                       className="animate-spin w-8 h-8 border-4 border-current border-t-transparent text-yellow-600 rounded-full ml-3"
                      role="status"
                      aria-label="loading"
                      style={{ marginTop: '10px', marginBottom: '10px' }} // Add margin for spacing

                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="Uploaded"
                        className="mt-2 h-32 w-32 object-cover"
                      />
                    )}
                  </div>
                  <div className="space-x-2 flex items-stretch">
                    <Button
                      size="md"
                      variant="simple"
                      onClick={() => props.onDone()}
                    >
                      Cancel
                    </Button>


                    
                    <Button
                      type="submit"
                      size="md"
                      disabled={pending}
                      isBlock={true}
                      className="w-20"
                    >
                      {!pending && <>Save</>}
                      {pending && <LoadingIcon className="w-5" />}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditItemModal;
